import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import i18n from '../translate'
import LoginTester from '../views/LoginTester.vue'

Vue.use(VueRouter)
function loadPage (pagePath) {
  return () => {
    return import(`@/${pagePath}`)
  };
}


const goTo = (to, from, next) => {
  console.log("ROUTE", this, from, next)
}
const routes = [
  {
    path: '/sitedown',
    name: 'Sitedown',
    component: loadPage('views/Maintenance.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: loadPage('views/Home.vue')
  },
  {
    path: '/geoLimit',
    name: 'geo',
    component: loadPage('views/GeoLimit.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: LoginTester,
  },
  {
    path: '/*',
    name: 'anyRootPath',
    component: loadPage('views/Home.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  routes
})


// CZ and SK are handled in the router guard
const campaigns = {
  'iade.kadinlarbilir.com': 'tr',
  'cashback.epithimies.gr': 'gr',
  'cashback.youtil.ro': 'ro',
  'geld-zurueck-aktion.for-me-online.de': 'de',
  'cashback.everydayme.hu': 'hu',
  'cashback.everydayme.pl': 'pl',
  'localhost:8080': 'gr',
  'localhost:8081': 'de',
  'pg-tr-cb.justsnap.eu': 'tr',
  'pg-de-cb.justsnap.eu': 'de',
  'pg-ro-cb.justsnap.eu': 'ro',
  'pg-gr-cb.justsnap.eu': 'gr',
  'pg-hu-cb.justsnap.eu': 'hu',
  'pg-hu-cz.justsnap.eu': 'cs',
  'pg-hu-sk.justsnap.eu': 'sk',
}


const czskCampaigns = {
  '30061': 'cs',
  '30062': 'cs',
  '30063': 'cs',
  '30067': 'cs',
  '30103': 'cs',
  '30152': 'cs',
  '30153': 'cs',
  '30154': 'cs',
  '30155': 'cs',
  '30193': 'cs',
  '30194': 'cs',
  '30195': 'cs',
  '30196': 'cs',
  '30197': 'cs',
  '30198': 'cs',
  '30064': 'sk',
  '30065': 'sk',
  '30066': 'sk',
  '30068': 'sk',
  '30104': 'sk',
  '30156': 'sk',
  '30157': 'sk',
  '30158': 'sk',
  '30159': 'sk',
  '30199': 'sk',
  '30200': 'sk',
  '30201': 'sk',
  '30202': 'sk',
  '30203': 'sk',
  '30204': 'sk',
}

const VUE_APP_SITE_DOMAIN = process.env.VUE_APP_SITE_DOMAIN
let toLoop = true;
router.beforeEach((to, from, next) => {
  if (to.name === "security" && toLoop) {
    toLoop = false;
    // router.push(".wellknown/security.txt")
    window.location.href = `${VUE_APP_SITE_DOMAIN}.wellknown/security.txt`;
  } else {
    try {
      const path = window.location.pathname.split('/').filter(Boolean)
      if (path.includes('moneyBack')) {
        const url = window.location.href.replace(/(^\w+:|^)\/\//, '').split('/')[0]
        if (url == 'cashback.mujsvet-pg.cz') {
          let campaign = require(`../store/campaigns/moneyback/${czskCampaigns[to.query.campaignId]}`).default
          store.replaceState(campaign);
          i18n.locale = czskCampaigns[to.query.campaignId]
          next ()
        } else {
          let campaign = require(`../store/campaigns/moneyback/${campaigns[url]}`).default
          store.replaceState(campaign);
          i18n.locale = campaigns[url]
          next ()
        }
      } else if (path.includes('wpbot')) {
        let campaign = require(`../store/campaigns/wpbot-tr`).default
        store.replaceState(campaign);
        i18n.locale = 'tr'
        next ()
      } else {
        const url = window.location.href.replace(/(^\w+:|^)\/\//, '').split('/')[0]
        if (url == 'cashback.mujsvet-pg.cz') {
          let campaign = require(`../store/campaigns/${czskCampaigns[to.query.campaignId]}`).default
          store.replaceState(campaign);
          i18n.locale = czskCampaigns[to.query.campaignId]
          next ()
        } else {
          let campaign = require(`../store/campaigns/${campaigns[url]}`).default
          store.replaceState(campaign);
          i18n.locale = campaigns[url]
          next ()
        }
      }
    } catch(e) {
      console.log("ERROR", e)
      next()
    }
  }
})

export default router
