export default {
  'Log in to your account': 'Přihlaste se do svého účtu',
  'Email': 'E-mail',
  'Password': 'Heslo',
  'Login': 'PŘIHLÁSIT SE',
  'Forgot your password?': 'Zapomněli jste heslo?',
  'Get SMS Code': 'ZÍSKEJTE SMS KÓD',
  'We sent you a verification code via sms': 'Odeslali jsme vám ověřovací kód prostřednictvím SMS',
  'Didnt receive it?': 'Nepřišlo vám to?',
  'Click here': 'Klikněte zde',
  'Change sms number': 'Změnit číslo mobilního telefonu',
  'Submit': 'ODESLAT',
  'Send': 'ODESLAT',
  'We got your bank information, and we’ll inform you via email when we’ll send the money to your bank account': 'Obdrželi jsme vaše bankovní údaje a budeme vás informovat e-mailem, když vám peníze pošleme na váš bankovní účet',
  'Your photo has been uploaded. Thank you!':'Vaše fotografie byla nahrána. Děkuju!',
  'Please upload a photo of the back of your product.': 'Nahrajte prosím fotografii zadní strany vašeho produktu',
  'Upload a receipt': 'Nahrajte účtenku',
  'In this step, please upload the proof of purchase of your products.': 'Nyní prosím nahrajte doklad o zaplacení vaších produktů',
  'Drop your photo here or select file.': 'Vložte svou fotografii zde nebo vyberte soubor.',
  'Only JPG, PNG and PDF files are supported Maximum file size: 10MB': 'Jsou podporovány pouze soubory JPG, PNG a PDF Maximální velikost souboru: 10MB',
  'Only JPG and PNG files are supported Maximum file size: 10MB': 'Jsou podporovány pouze soubory JPG a PNG Maximální velikost souboru: 10MB',
  'UPLOAD': 'NAHRÁT',
  'The following information must be visible:': 'Následující informace musí být viditelné:',
  'Folded or scanned receipts or invoices will not be accepted': 'Přeložené nebo naskenované účtenky nebo faktury nebudou přijaty',
  'Retailer / Shop': 'Maloobchodník / Obchod',
  'Date and time of purchase': 'Datum a čas nákupu',
  'Campaign product and its price': 'Produkt kampaně a jeho cena',
  'Total sum of the receipt / invoice and applied VAT': 'Celková uhrazená částka účtenky/faktury a DPH',
  'Receipt / Invoice ID (not the bank transaction ID)': 'Účtenka / faktury (nikoliv ID bankovní transakce)',
  'Correct': 'Správný',
  'Not readable': 'Nečitelné',
  'Folded': 'Naskládané',
  'Cropped': 'Oříznuté',
  'Back': 'Zadní strana',
  'Continue': 'Pokračovat',
  'Please erase your personal data with the erase tool': 'Vymažte prosím své osobní údaje pomocí nástroje vymazat',
  'Crop': 'Oříznout',
  'Erase': 'Vymazat',
  'Drag': 'Táhnout',
  'Zoom in': 'Přiblížit',
  'Zoom out': 'Oddálit',
  'Provide bank details': 'Poskytněte bankovní údaje',
  'Please let us know to which account the refund amount should be transferred.': 'Dejte nám prosím vědět, na který účet máme polslat vrácenou častku',
  'Account holder first name': 'Křestní jméno držitele účtu',
  'Account holder last name': 'Příjmení držitele účtu',
  'IBAN': 'IBAN',
  'BIC': 'BIC',
  'This field is mandatory': 'Toto pole je povinné',
  'Enter the frist name of the account holder': 'Zadejte křestní jméno držitele účtu',
  'Enter the last name of the account holder': 'Zadejte příjmení držitele účtu',
  'Enter your IBAN number': 'Zadejte své číslo IBAN',
  'Enter your BIC': 'Zadejte svůj BIC',
  'Please enter a valid IBAN': 'Zadejte prosím platný IBAN',
  'Confirm your identity and claim your refund': 'Potvrďte svou identitu a zíjskejte vrácenou častku',
  'Verify your identity by entering your phone number here. It is only used once for verification and not for advertising purposes. After the action, your phone number will be deleted by us.': 'Ověřte svou identitu zadáním telefonního čísla zde. Bude použíto pro jednorázové ověření a ne pro reklamní účely.  Po ověření bude vaše telefonní číslo smazáno.',
  'Mobile phone number': 'Číslo mobilního telefonu',
  'We have sent you a code via SMS to the phone number you provided. Please enter this in the field below. If you did not receive a code, please change your phone number and try again': 'Poslali jsme vám kód SMS kód na telefonní číslo, které jste poskytli. Zadejte ho do pole níže. Pokud jste kód neobdrželi,  změňte prosím své telefonní číslo a zkuste to znovu',
  'Verification code': 'Ověřovací kód',
  'Change the mobile number': 'Změňit číslo mobilního telefonu',
  'The SMS code is incorrect': 'Nesprávný SMS kód',
  'Thank you!!': 'Děkuju!!',
  'THANK YOU for participating in this campaign.': 'Děkujeme za Vaší účast v kampani „xxx“.',
  'Your receipt / invoice and your data have been successfully sent to us and are being checked at the moment.As soon as we have checked your data, you will receive either a confirmation e-mail or an e-mail asking us if your data was incomplete or your proof of purchase could not be recorded. Please keep your reference number.': 'Vaše účtenka/faktura a údaje nám byly úspěšně odeslány a  v tuto chvíli probíha jejich kontrola. Jakmile budou Vaše údaje zkontrolováný, obdržíte buď potvrzovací e-mail, nebo e-mail který vás bude informovat o nekompletních údajich nebo o nepodařenén ověření  Vašeho dokladu o zaplacení.  Zaznamenejte si prosím své referenční číslo',
  'Reference number': 'Referenční číslo:',
  'Copy': 'Kopírovat',
  'The verification can take up to 72 hours. We ask for your patience.': 'Ověření může trvat až 72 hodin. Žádáme o vaši trpělivost.',
  'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.': 'Tento formát souboru není podporován. Jsou přijímány pouze soubory .jpeg, .png a .pdf.',
  'This file format is not supported. Only .jpeg and .png files are accepted.': 'Tento formát souboru není podporován. Jsou přijímány pouze soubory .jpeg a .png.',
  'This file is too big. Only files up to 10MB can be uploaded.': 'Tento soubor je příliš velký. Lze nahrát pouze soubory do velikosti 10MB.',
  'Your receipt will now be uploaded and processed.': 'Vaše účtenka bude nyní nahrána a zpracována.',
  'The receipt will be processed automatically. This may take a minute. Please be patient.': 'Účtenka bude zpracována automaticky. Může to chvíli trvat. Prosím, buďte trpěliví.',
  'If you receive an error message, please record a better version of the receipt and upload it again.': 'Pokud obdržíte chybové hlášení, poskytněte prosím čitělnějsí verzi potvrzení a znovu ji nahrajte.',
  'Please erase your personal data with the eraser tool.': 'Vymažte prosím své osobní údaje pomocí nástroje guma.',
  'Please frame the receipt / invoice in the gray rectangle.': 'Zarámujte potvrzení/fakturu šedím obdélníkem.',
  'First name': 'Jméno',
  'Last name': 'Příjmení',
  'Phone number': 'Telefonní číslo',
  'SMS code': 'SMS kód',
  'IBAN is not valid': 'IBAN není platný',
  'First name must contain only letters, hyphens and spaces': 'Křestní jméno může obsahovat pouze písmena, pomlčky a mezery',
  'Last name must contain only letters, hyphens and spaces': 'Příjmení může obsahovat pouze písmena, pomlčky a mezery',
  'receipt-image': 'assets/images/svg/receipt-cs.svg',
  'profiling-text': 'Data z vámi nahraných účtenek budou použity pro přizpůsobení našeho sdělení a nabídek, které vás mohou zajímat. Seznamte se s podmínkami našich kampaní zde: <a href="https://mujsvet-pg.cz/nabidky/pravidla" target="_blank"><u>https://mujsvet-pg.cz/nabidky/pravidla</u></a>.<br>Svoje předvolby a odběry změnit kliknutím <a href="https://mujsvet-pg.cz/nabidky/pravidla" target="_blank"><u>sem</u></a>.',
  'The phone number is not valid': 'Telefonní číslo není platné',
  'Copied to clipboard': 'Zkopírované do klipboardu',
  'Oops, something went wrong!': 'Jejda! Něco se pokazilo!',
  'The link you are trying to use has already expired. Please request a new password reset link.': 'Odkaz, který se pokoušíte použít, již není platný. Požádejte prosím o nový odkaz pro resetování hesla.',
  'An error occurred because the receipt was uploaded too many times.': 'Došlo k chybě, protože účtenka byla nahrána příliš mnohokrát.',
  'The verification code could not be sent because the phone number is not allowed. Please check the number you entered and try again.': 'Ověřovací kód nebylo možné odeslat, protože telefonní číslo není platné. Zkontrolujte zadané číslo a zkuste to znovu.',
  'Oops. An error has occurred on the server. Please try again later.': 'Jejda. Na serveru došlo k chybě. Zkuste to prosím znovu později.',
  'Oops...': 'Jejda!',


  'Specify barcode number': 'Zadejte číslo čárového kódu',
  'Please scan or enter manually the barcode from your product': 'Naskenujte nebo zadejte ručně čárový kód z vašeho produktu',
  'SCAN': 'OSKENUJTE',
  'APPLY': 'VLOŽTE KÓD',
  'STOP': 'STOP',
  'The barcode number is invalid. Try it again.': 'Číslo čárového kódu je neplatné. ZkusTE to znovu.',
  'Unfortunately no valid barcode can be recognized. Please try to enter the barcode in the field above.': 'Bohužel nelze rozpoznat žádný platný čárový kód. Zkuste prosím zadat čárový kód do pole výše.',
  'Please turn on your camera': 'Zapněte fotoaparát',
  'Click here and scan the barcode.': 'Klikněte sem a naskenujte čárový kód.',
  'Enter barcode number': 'Zadejte číslo čárového kódu',
  'Take a photo of your product unpacked next to the piece of paper with the written unique code on it': 'Vyfoťte svůj produkt rozbalený vedle kusu papíru s napsaným unikátním kódem',
  'Please write a piece of paper this number': 'Napište prosím toto číslo na kus papíru',
  'Skip': 'Přeskočit',
  'Expired Total': 'ODKAZ VYPRŠEL',

  errors: {
    default: "Jejda!!! Něco se pokazilo!",
    ibanUsed: 'Tento IBAN již byl použit. Zadejte prosím nový IBAN, ujistěte se, že může přijímat platby ze zahraničí.',
    'IBAN is alreaedy in use': 'Tento IBAN již byl použit. Zadejte prosím nový IBAN, ujistěte se, že může přijímat platby ze zahraničí.',
    customerToken: "Jejda!!! Něco se pokazilo, začněte prosím znovu!",
    invalidCode: "Odkaz, který se pokoušíte použít, již není platný. Požádejte prosím o nový odkaz pro resetování hesla.",
    manyTries: 'Došlo k chybě, protože účtenka byla nahrána příliš mnohokrát.',
    generateCode: 'Ověřovací kód nebylo možné odeslat, protože telefonní číslo není platné. Zkontrolujte zadané číslo a zkuste to znovu.',
    wrongCode: 'Nesprávný SMS kód',
    network: 'Jejda. Na serveru došlo k chybě. Zkuste to prosím znovu později.',
    invalidReceipt: 'Toto není účtenka, nahrajte fotografii platné účtenky.'
  },
  dictionary: {
    cs: {
      messages: {
        "namesWithHyphensAndSpaces": `{_field_} může obsahovat pouze písmena, pomlčky a mezery`,
        "ibanName": `{_field_} může obsahovat pouze písmena, pomlčky a mezery`,
        "iban": "IBAN není platný",
        "phoneNumber": "Telefonní číslo není platné",
        "mandatory": "Toto pole je povinné",
      },
      names: {
        iban: 'IBAN',
        firstname: 'Jméno',
        firstName: 'Jméno',
        lastname: 'Příjmení',
        lastName: 'Příjmení',
        phoneNumber: 'Telefonní číslo',
        code: 'SMS kód'
      }
    }
  },

  'Request your payout': 'Požádat o vyplacení',
  'Please enter the information of your account to which the refund amount will be transferred.': 'Zadejte prosím informace o svém účtu, na který bude převedena vrácená částka.',
  'Procter & Gamble is data controller of the personal data you provide. Data related to your bank account number will be processed for the purpose of providing you with money refund. Your phone number will be processed for the purpose of preventing frauds and abuses. You can exercise your data protection rights or change your preferences and subscriptions by clicking here. You can find out more about how we use your data in our Privacy Policy.': `Společnost <a href='https://privacypolicy.pg.com/cs/#entities' target='_parent'><u>Procter & Gamble</u></a> je správcem osobních údajů, které poskytujete. Údaje týkající se čísla vašeho bankovního účtu budou zpracovány za účelem vrácení peněz. Vaše telefonní číslo bude zpracováno za účelem prevence podvodů a zneužití.
  <br>
  Svoje <span class='modalOpener' data-text='Jak uvedeno v našich zásadách ochrany osobních údajů, máte neomezený přístup ke svým osobním údajům, které o vás evidujeme, a můžete je opravovat, mazat, přenášet k jinému poskytovateli, můžete vznést námitku proti jejich zpracování nebo jejich zpracování omezit či svůj souhlas odvolat.'>práva na ochranu údajů</span> můžete uplatnit a svoje předvolby a odběry změnit kliknutím <a href='https://privacypolicy.pg.com/cs/#preferences' target='_parent'><u>sem</u></a>.
  <br>
  Více o tom, jak používáme vaše údaje, najdete v našich <a href='https://privacypolicy.pg.com/cs/' target='_parent'><u>zásadách ochrany osobních údajů</u></a>.`,
  'Data from your uploaded receipts will be used to tailor our communications and offers that may be of interest to you. Get to know the conditions of our campaigns here: https://mujsvet-pg.cz/nabidky/pravidla. Change your settings here.': `Data z vámi nahraných účtenek budou použity pro přizpůsobení našeho sdělení a nabídek, které vás mohou zajímat. Seznamte se s podmínkami našich kampaní zde: <a href='https://mujsvet-pg.cz/nabidky/pravidla' target='_blank'>https://mujsvet-pg.cz/nabidky/pravidla</a>. Svoje předvolby a odběry změnit kliknutím <a href='https://mujsvet-pg.cz/user/profilena' target='_blank'>sem</a>.`,
  'Consent to email marketing. You wish to receive emails, offers and coupons from https://www.mujsvet-pg.cz/ and other trusted P&G brands .': `Souhlas s e-mailovým marketingem. Přejete si dostávat e-maily, nabídky a kupony od <a href='https://www.mujsvet-pg.cz/' target='_blank'>https://www.mujsvet-pg.cz/</a> a jiných důvěryhodných <a href='https://us.pg.com/brands/' target='_blank'>značek P&G</a>.`,
  'Payout disclaimer': `
  <ul>
    <li>
      Společnost <a href='https://privacypolicy.pg.com/cs/#entities' target='_parent'><u>Procter & Gamble</u></a> je správcem osobních údajů, které poskytujete. Údaje týkající se čísla vašeho bankovního účtu budou zpracovány za účelem vrácení peněz. Vaše telefonní číslo bude zpracováno za účelem prevence podvodů a zneužití.
      <br>
      Svoje <span class='modalOpener' data-text='Jak uvedeno v našich zásadách ochrany osobních údajů, máte neomezený přístup ke svým osobním údajům, které o vás evidujeme, a můžete je opravovat, mazat, přenášet k jinému poskytovateli, můžete vznést námitku proti jejich zpracování nebo jejich zpracování omezit či svůj souhlas odvolat.'>práva na ochranu údajů</span> můžete uplatnit a svoje předvolby a odběry změnit kliknutím <a href='https://privacypolicy.pg.com/cs/#preferences' target='_parent'><u>sem</u></a>.
      <br>
      Více o tom, jak používáme vaše údaje, najdete v našich <a href='https://privacypolicy.pg.com/cs/' target='_parent'><u>zásadách ochrany osobních údajů</u></a>.
    </li>
    <li>
      Data z vámi nahraných účtenek budou použity pro přizpůsobení našeho sdělení a nabídek, které vás mohou zajímat. Seznamte se s podmínkami našich kampaní zde: <a href='https://mujsvet-pg.cz/nabidky/pravidla' target='_blank'>https://mujsvet-pg.cz/nabidky/pravidla</a>. Svoje předvolby a odběry změnit kliknutím <a href='https://mujsvet-pg.cz/user/profilena' target='_blank'>sem</a>.
    </li>
    <li>
      Souhlas s e-mailovým marketingem. Přejete si dostávat e-maily, nabídky a kupony od <a href='https://www.mujsvet-pg.cz/' target='_blank'>https://www.mujsvet-pg.cz/</a> a jiných důvěryhodných <a href='https://us.pg.com/brands/' target='_blank'>značek P&G</a>.
    </li>
  </ul>
  `,

  // GOogle translated
  'Oops, something went wrong. Please try again.': 'Jejda! Něco se pokazilo. Prosím zkuste to znovu.',
  'This session has expired': 'Platnost této relace vypršela',
  'Unfortunately the link is no longer valid! Please participate again or contact our support team': 'Bohužel odkaz již není platný! Zúčastněte se prosím znovu nebo kontaktujte náš tým podpory',
  'Unfortunately the link is no longer valid':'Bohužel odkaz již není platný',
  'The promotion period is over': 'Propagační období skončilo',
  'Sign up to stay up to date. Receive special offers and updates.': 'Chcete-li zůstat v obraze, přihlaste se. Získejte speciální nabídky a aktualizace.',
  'Please upload your receipt again here': 'Nahrajte sem prosím svou účtenku znovu',
  'Please upload your product photo here': 'Zde nahrajte fotografii svého produktu',
  'Back to campaign page': 'Zpět na stránku kampaně',
  'Please enter the first name and last name of the account holder exactly as they would be given at the bank.': 'Zadejte prosím jméno a příjmení majitele účtu přesně tak, jak by byly uvedeny v bance.',
  'Please do not try to enter the same bank details that the first attempt at transfer failed with.': 'Nepokoušejte se prosím zadávat stejné bankovní údaje, u kterých selhal první pokus o převod.',
  'Thank you for resubmitting your bank details.<br>So there should now be a successful transfer. If you have any questions related to your bank details, please contact link.': 'Děkujeme za opětovné odeslání bankovních údajů.<br>Nyní by tedy mělo dojít k úspěšnému převodu. Máte-li jakékoli dotazy týkající se vašich bankovních údajů, kontaktujte prosím <a href="https://mujsvet-pg.cz/kontaktujte-nas" target="_blank"><u>https://mujsvet-pg.cz/kontaktujte-nas</u></a>.'
}
