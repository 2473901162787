
export default {
  isEditMode: true,
  pageProperties: {
    testing: false,
    "pages": [
      { // Campaign
        "pageName": "home",
        isPublic: true,
        pageId: 101,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "50px",
              "marginTop": "50px",
            },
            "classes": "container",
            "sectionId": "loginSection",
            "fields": [
              { // Container
                "fieldId": "jakldklf",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                  },
                  fields: [
                    {
                      "fieldType": "query-string-to-state",
                      "fieldId": "query-string-to-state",
                      "properties": {
                        grid: { columns: 12 },
                        variables: [
                          { state: 'customerToken', query: "token" },
                          { state: 'receiptToken', query: "receiptToken" },
                          { state: 'campaignId', query: "campaignId", defaultValue: "joltx-de-immediate-second-user-journey" },
                          { state: 'referenceNumber', query: "referenceNumber" }
                        ]
                      }
                    },
                    {
                      "fieldType": "check-elgibility",
                      "fieldId": "check-elgibility",
                      "properties": {
                        grid: { columns: 12 },
                        endpoint: "/checkEligibility",
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Log in to your account",
                        classes: 'heading text-center'
                      }
                    },
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "login",
                      "properties": {
                        "successAction": {
                          "fn": "goToPage",
                          "params": "userData"
                        },
                        "endpoint": "/janrainLogin",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                              },
                              "fields": [
                                {
                                  "fieldType": "empty-bucket",
                                  "fieldId": "f934if",
                                  "properties": {
                                    "grid": {
                                      "columns": 8,
                                      "justifyContent": "center",
                                      "alignItems": "center",
                                      "flexDirection": "column",
                                      "rowGap": "30px",
                                      marginBottom: "50px"
                                    },
                                    "fields": [
                                      { // First name
                                        "fieldId": "tp6zfb",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "email",
                                          "id": "email",
                                          "label": "Email",
                                          "validation": "required|email",
                                          "labelOnTop": true,
                                          "placeholder": "Email",
                                          "text": "",
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      },
                                      { // Last name
                                        "fieldId": "p55fao",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "password",
                                          "id": "password",
                                          "validation": "required",
                                          "labelOnTop": true,
                                          "label": "Password",
                                          "placeholder": "Password",
                                          "inputType": "password",
                                          "text": "",
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      }
                                    ]
                                  }
                                },
                                { // Buttons
                                  "fieldId": "fu0923r",
                                  "fieldType": "empty-bucket",
                                  "properties": {
                                    "grid": {
                                      "columns": 6,
                                      "justifyContent": "center",
                                      "alignItems": "center",
                                      "flexDirection": "column"
                                    },
                                    fields: [
                                      { // SEND Button
                                        "fieldType": "button-component",
                                        "fieldId": "but100",
                                        properties: {
                                          grid: {
                                            columns: 3,
                                            marginBottom: "30px"
                                          },
                                          text: "Login",
                                          classes: "btn-dach",
                                          requires: ['email', 'password'],
                                          "action": [
                                            {
                                              "fn": "sendForm",
                                              "params": "login"
                                            }
                                          ]
                                        }
                                      },
                                      { // SEND Button
                                        "fieldType": "anchor",
                                        "fieldId": "but100",
                                        properties: {
                                          grid: {
                                            columns: 12
                                          },
                                          text: "Forgot your password?",
                                          url: 'https://www.for-me-online.de/passwort-vergessen',
                                          target: '_blank',
                                          styles: {
                                            "font-size": "18px",
                                            "line-height": "38px",
                                            "font-weight": "700",
                                            "color": "#2F80ED",
                                            "text-align": "center",
                                          }
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // User data
        "pageName": "userData",
        isPublic: true,
        pageId: 100,
        "sections": [
          // { // Steps and Header
          //   "grid": {
          //     "columns": 12,
          //     "alignItems": "center",
          //     "justifyContent": "center",
          //     "marginBottom": "50px",
          //     "marginTop": "50px",
          //   },
          //   "classes": "container",
          //   "sectionId": "smsSection",
          //   "fields": [
          //     { // Container
          //       "fieldId": "jakldklf",
          //       "fieldType": "empty-bucket",
          //       "properties": {
          //         "grid": {
          //           "columns": 12,
          //           "alignItems": "center",
          //           "justifyContent": "center",
          //         },
          //         fields: [
          //           { // Heading
          //             "fieldType": "paragraph",
          //             "fieldId": "par1001",
          //             properties: {
          //               grid: {
          //                 columns: 12,
          //                 marginBottom: "15px"
          //               },
          //               text: "Confirm your identity and claim your refund",
          //               classes: 'heading text-left'
          //             }
          //           },
          //           { // SubHeading
          //             "fieldType": "paragraph",
          //             "fieldId": "par1001",
          //             properties: {
          //               grid: {
          //                 columns: 12,
          //                 marginBottom: "50px"
          //               },
          //               text: "Verify your identity by entering your phone number here. It is only used once for verification and not for advertising purposes. After the action, your phone number will be deleted by us.",
          //               classes: 'subHeading text-left'
          //             }
          //           },
          //           { // SMS
          //             "fieldType": "sms-verification",
          //             "fieldId": "smsForm",
          //             "properties": {
          //               "grid": {
          //                 "alignItems": "center",
          //                 "justifyContent": "center",
          //                 "columns": 12,
          //                 marginBottom: "50px"
          //               },
          //               "endpoint": "/mobileAuth",
          //               "endpoint2": "/mobileVerification",
          //               "inputWidth": "720px",
          //               "successAction": {
          //                 "fn": "goNextStep",
          //                 "params": "ibanSection"
          //               },
          //               "colorSchema": "#FB850F",
          //               "btnClasses": "btn-dach",
          //               "texts": {
          //                 "phoneLabel": "Phone number",
          //                 "sendBtn": "Get SMS Code",
          //                 "codeLabel": "Verification code",
          //                 "codeSentLabel": "We sent you a verification code via sms",
          //                 "notReceived": "Didnt receive it?",
          //                 "clickHere": "Click here",
          //                 "changeNumber": "Change sms number",
          //                 "codeSendBtn": "Send",
          //               },
          //               options: [
          //                 {
          //                   name: "Germany",
          //                   code: "de",
          //                   phoneCode: "+49",
          //                 },
          //                 {
          //                   name: "Austria",
          //                   code: "at",
          //                   phoneCode: "+43",
          //                 },
          //                 {
          //                   name: "Switzerland",
          //                   code: "ch",
          //                   phoneCode: "+41",
          //                 }
          //               ],
          //             }
          //           },
          //         ]
          //       }
          //     }
          //   ]
          // },
          { // IBAN Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "50px",
            },
            "classes": "container",
            "sectionId": "ibanSection",
            // "requires": ['smsSection'],
            "fields": [
              { // IBAN Bucket
                "fieldId": "ibanSection",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "borderTop": "46px solid #FB850F",
                    "paddingTop": "50px"
                  },
                  fields: [
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "lastStepForm",
                      "properties": {
                        "successAction": {
                          "fn": "goToPage",
                          "params": "thank-you"
                        },
                        "endpoint": "/submitIban",
                        // "requires": ['smsForm'],
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                              },
                              "fields": [
                                { // Heading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "15px"
                                    },
                                    text: "Provide bank details",
                                    classes: 'title text-left'
                                  }
                                },
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "30px"
                                    },
                                    text: "Please let us know to which account the refund amount should be transferred.",
                                    classes: 'subTitle text-left'
                                  }
                                },
                                {
                                  "fieldType": "empty-bucket",
                                  "fieldId": "f934if",
                                  "properties": {
                                    "grid": {
                                      "columns": 8,
                                      "justifyContent": "center",
                                      "alignItems": "center",
                                      "flexDirection": "column",
                                      "rowGap": "30px",
                                      marginBottom: "50px"
                                    },
                                    "fields": [
                                      { // First name
                                        "fieldId": "tp6zfb",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "firstname",
                                          "id": "firstname",
                                          "label": "Account holder first name",
                                          "validation": "required|ibanName",
                                          "labelOnTop": true,
                                          "placeholder": "Account holder first name",
                                          "text": "",
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      },
                                      { // Last name
                                        "fieldId": "p55fao",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "lastname",
                                          "id": "lastname",
                                          "validation": "required|ibanName",
                                          "labelOnTop": true,
                                          "label": "Account holder last name",
                                          "placeholder": "Account holder last name",
                                          "text": "",
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      },
                                      { // IBAN
                                        "fieldId": "h9arlr",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "iban",
                                          "id": "iban",
                                          "validation": "required|iban",
                                          "labelOnTop": true,
                                          "label": "IBAN",
                                          "placeholder": "Enter your IBAN number",
                                          "text": "",
                                          "isUpperCase": true,
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      }
                                    ]
                                  }
                                },
                                { // Buttons
                                  "fieldId": "fu0923r",
                                  "fieldType": "empty-bucket",
                                  "properties": {
                                    "grid": {
                                      "columns": 6
                                    },
                                    fields: [
                                      { // SEND Button
                                        "fieldType": "button-component",
                                        "fieldId": "but100",
                                        properties: {
                                          grid: {
                                            columns: 3
                                          },
                                          text: "Continue",
                                          classes: "btn-dach",
                                          requires: ['firstname', 'lastname', 'iban'],
                                          "action": [
                                            {
                                              "fn": "sendForm",
                                              "params": "lastStepForm"
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Thank you
        "pageName": "thank-you",
        pageId: 34673457,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "classes": "container",
            "fields": [
              { // Thank you Bucket
                "fieldId": "thankBox",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "50px"
                  },
                  fields: [
                    { // Key visual
                      "fieldType": "key-visual",
                      "fieldId": "key100",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        styles: {
                          // "padding": '0 0 0 0'
                        },
                        noContainer: true,
                        desktopImageUrl: "assets/images/banner/banner-dach.png",
                        tabletImageUrl: "assets/images/banner/banner-dach.png",
                        mobileImageUrl: "assets/images/banner/banner-dach.png"
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "30px"
                        },
                        text: "We got your bank information, and we’ll inform you via email when we’ll send the money to your bank account",
                        classes: 'text text-center'
                      }
                    }
                  ]
                }
              },
              {
                "fieldType": "empty-bucket",
                "fieldId": "82358325",
                "properties": {
                  "grid": {
                    "columns": "5",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "fields": [
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Back to forme page",
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginBottom": "50px"
                        },
                        classes: "btn-dach",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.for-me-online.de/geld-zurueck-aktionen"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Thank you iframe
        "pageName": "thank-you-iframe",
        pageId: 134673457,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "classes": "container",
            "fields": [
              { // Thank you Bucket
                "fieldId": "thankBox",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "50px"
                  },
                  fields: [
                    // { // Key visual
                    //   "fieldType": "key-visual",
                    //   "fieldId": "key100",
                    //   properties: {
                    //     grid: {
                    //       columns: 12,
                    //       marginBottom: "50px"
                    //     },
                    //     styles: {
                    //       // "padding": '0 0 0 0'
                    //     },
                    //     noContainer: true,
                    //     desktopImageUrl: "assets/images/banner/banner-dach.png",
                    //     tabletImageUrl: "assets/images/banner/banner-dach.png",
                    //     mobileImageUrl: "assets/images/banner/banner-dach.png",
                    //     hideInLocales: ['fr']
                    //   }
                    // },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "30px"
                        },
                        text: "You're almost there! What happens now?",
                        classes: 'text text-center mt-5',
                        variables: ['referenceNumber']
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Thank you Reupload
        "pageName": "thank-you-reupload",
        pageId: 34673458,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "classes": "container",
            "fields": [
              { // Thank you Bucket
                "fieldId": "thankBox",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "50px"
                  },
                  fields: [
                    { // Key visual
                      "fieldType": "key-visual",
                      "fieldId": "key100",
                      properties: {
                        grid: {
                          columns: 12
                        },
                        styles: {
                          // "padding": '0 0 0 0'
                        },
                        noContainer: true,
                        desktopImageUrl: "assets/images/banner/banner-dach.png",
                        tabletImageUrl: "assets/images/banner/banner-dach.png",
                        mobileImageUrl: "assets/images/banner/banner-dach.png",
                        hideInLocales: ['fr']
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "30px",
                          marginTop: "50px"
                        },
                        text: "Your photo has been successfully submitted to us and is now being checked. As soon as your successful participation in the campaign has been confirmed by checking your data, you will receive a corresponding email. If it turns out that your data was incomplete or the purchase receipt could not be recorded, you will receive an email with relevant queries. The testing process can take up to 36 hours; We therefore ask you to be patient.",
                        classes: 'text text-center'
                      }
                    }
                  ]
                }
              },
              {
                "fieldType": "empty-bucket",
                "fieldId": "82358325",
                "properties": {
                  "grid": {
                    "columns": "5",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "fields": [
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Back to forme page",
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginBottom": "50px"
                        },
                        classes: "btn-dach",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.for-me-online.de/geld-zurueck-aktionen"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // 404
        "pageName": "error-page",
        pageId: 34646,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "secondaryErrorBucket",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "60px",
                    "marginBottom": "60px"
                  },
                  classes: "maxWidthOverride transparentBg errorPageMarginTop",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "oopsErrorPage",
                      "properties": {
                        "text": `Oops!!!`,
                        classes: "text-center header-text",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginTop": "50px",
                          "marginBottom": "40px",
                        }
                      }
                    },
                    { // Key visual
                      "fieldType": "key-visual",
                      "fieldId": "key100",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px",
                        },
                        styles: {
                          "padding": '0 0 0 0;',
                          "max-width": '400px'
                        },
                        noContainer: true,
                        desktopImageUrl: "assets/images/svg/error-dach.svg",
                        tabletImageUrl: "assets/images/svg/error-dach.svg",
                        mobileImageUrl: "assets/images/svg/error-dach.svg"
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "errorSubHeading",
                      "properties": {
                        "text": `Oops, something went wrong. Please try again.`,
                        "classes": 'text text-center subheader-text',
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginTop": "10px",
                          "marginBottom": "50px"
                        }
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "82358325",
                      "properties": {
                        "grid": {
                          "columns": "5",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "button-component",
                            "fieldId": "errorBackButton",
                            "properties": {
                              "text": "Back to campaign page",
                              "grid": {
                                "columns": "12",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              classes: "btn-dach",
                              "disabled": false,
                              "action": {
                                "anchor": true,
                                "link": "/"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Expired Page
        "pageName": "expired-page",
        pageId: 34647,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "secondaryErrorBucket",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "60px",
                    "marginBottom": "60px"
                  },
                  classes: "maxWidthOverride transparentBg errorPageMarginTop",
                  fields: [
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "82358325",
                      "properties": {
                        "grid": {
                          "columns": "6",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "fields": [
                          { // Heading
                            "fieldType": "paragraph",
                            "fieldId": "oopsErrorPage",
                            "properties": {
                              "text": `Unfortunately the link is no longer valid`,
                              classes: "heading text-left",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "8",
                                "marginTop": "50px",
                                "marginBottom": "40px",
                              }
                            }
                          },
                        ]
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "82358325",
                      "properties": {
                        "grid": {
                          "columns": "6",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginBottom": "50px",
                        },
                        "fields": [
                          {
                            "fieldType": "square-image",
                            "fieldId": "5vk986f7",
                            "properties": {
                              "imageSource": "assets/images/png/expired-de.png",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": 6,
                                "marginBottom": "50px",
                              },
                              "className": "col-md-12 col-lg-6",
                              "styles": {
                                "maxWidth": "400px",
                                "object-fit": "contain"
                              }
                            }
                          }
                        ]
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "82358325",
                      "properties": {
                        "grid": {
                          "columns": "5",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "button-component",
                            "fieldId": "errorBackButton",
                            "properties": {
                              "text": "Back to campaign page",
                              "grid": {
                                "columns": "12",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              classes: "btn-dach",
                              "disabled": false,
                              "action": {
                                "anchor": true,
                                "link": "/"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt Re Upload
        "pageName": "receipt-reupload",
        isPublic: true,
        pageId: 119,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
            },
            "sectionId": "fu30zq",
            "classes": "container",
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    },
                    {
                      query: "token",
                      state: "receiptToken"
                    },
                    {
                      query: "campaignId",
                      state: "campaignId"
                    },
                  ]
                }
              },
              { // 72 Hours check
                "fieldType": "checkReceiptToken",
                "fieldID": "dksnfsdkjf",
                properties: {
                  grid: { columns: 12 }
                }
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "40px"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Upload a receipt",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "25px"
                        },
                        text: "In this step, please upload the proof of purchase of your products.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Receipt uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "space-around",
                          "marginBottom": "60px",
                          "marginTop": "50px"
                        },
                        "classes": "receipt-bucket",
                        "fields": [
                          { // Recipt upload
                            "fieldType": "receipt-processor",
                            "fieldId": "recReupload",
                            "properties": {
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "successAction": {
                                "fn": "goToPage",
                                "params": "thank-you-reupload"
                              },
                              texts: {
                                choose: 'UPLOAD',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG, PNG and PDF files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.',
                                fileSizeLimit: 'This file is too big. Only files up to 10MB can be uploaded.'
                              },
                              editTexts: {
                                crop: "Crop",
                                erase: "Erase",
                                drag: "Drag",
                                zoomIn: "Zoom in",
                                zoomOut: "Zoom out"
                              },
                              reUpload: true,
                              "colorSchema": "#FB850F",
                              "phrases": [
                                "Your receipt is now being loaded and processed.",
                                "The receipt will be processed automatically. This may take a minute. Please be patient.",
                                "If you get an error message, please get a better version of the receipt and upload it again."
                              ],
                              "cornerDetectionPhrases": [
                                "Please drag your receipt inside the gray rectangle.",
                                "You can erase private information with the Eraser tool.",
                              ],
                            }
                          },
                          { // Receipt Info
                            "fieldType": "empty-bucket",
                            "fieldId": "vdf932fd",
                            "properties": {
                              "grid": {
                                "columns": "6"
                              },
                              "classes": "addMarginLeft",
                              "fields": [
                                {
                                  "fieldType": "receipt-information",
                                  "fieldId": "5mq1p6",
                                  "properties": {
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": 12
                                    },
                                    shouldDisplayFull: true,
                                    "texts": {
                                      "descText": `The following information must be visible:`,
                                      "descSubText": `Folded or scanned receipts or invoices will not be accepted`,
                                      "correct": "Valid",
                                      "notReadabe": "Hard to read",
                                      "folded": "Folded",
                                      "cropped": "Cut off"
                                    },
                                    "hideExamples": false,
                                    "receiptImage": "assets/images/svg/receipt-dach.svg",
                                    "steps": [
                                      { // Retailer
                                        "text": "Retailer / Shop",
                                        "label": "A"
                                      },
                                      {
                                        "text": "Date and time of purchase",
                                        "label": "B"
                                      },
                                      {
                                        "text": "Campaign product and its price",
                                        "label": "C"
                                      },
                                      {
                                        "text": "Total sum of the receipt / invoice and applied VAT",
                                        "label": "D"
                                      },
                                      {
                                        "text": "Receipt / Invoice ID (not the bank transaction ID)",
                                        "label": "E"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "3",
                          "marginBottom": "30px"
                        },
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 12
                              },
                              text: "Send",
                              classes: "btn-dach",
                              "requires": ["recReupload"],
                              "action": {
                                "fn": "sendReceipt",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt + Product Re Upload
        "pageName": "receipt-product-reupload",
        isPublic: true,
        pageId: 1191,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
            },
            "sectionId": "fu30zq",
            "classes": "container",
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    },
                    {
                      query: "token",
                      state: "receiptToken"
                    },
                    {
                      query: "campaignId",
                      state: "campaignId"
                    },
                  ]
                }
              },
              { // 72 Hours check
                "fieldType": "checkReceiptToken",
                "fieldID": "dksnfsdkjf",
                properties: {
                  grid: { columns: 12 }
                }
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 8,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "40px"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Upload proof of purchase + product back",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "25px"
                        },
                        text: "In this step, please upload the purchase receipt for your product together with the back of the product. Please make sure that the PZN of the product is clearly visible.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Receipt uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "space-around",
                          "marginBottom": "60px",
                          "marginTop": "50px"
                        },
                        "classes": "receipt-bucket",
                        "fields": [
                          { // Recipt upload
                            "fieldType": "receipt-processor",
                            "fieldId": "recReupload",
                            "properties": {
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "successAction": {
                                "fn": "goToPage",
                                "params": "thank-you-reupload"
                              },
                              texts: {
                                choose: 'UPLOAD',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG, PNG and PDF files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.',
                                fileSizeLimit: 'This file is too big. Only files up to 10MB can be uploaded.'
                              },
                              editTexts: {
                                crop: "Crop",
                                erase: "Erase",
                                drag: "Drag",
                                zoomIn: "Zoom in",
                                zoomOut: "Zoom out"
                              },
                              reUpload: true,
                              "colorSchema": "#FB850F",
                              "phrases": [
                                "Your receipt is now being loaded and processed.",
                                "The receipt will be processed automatically. This may take a minute. Please be patient.",
                                "If you get an error message, please get a better version of the receipt and upload it again."
                              ],
                              "cornerDetectionPhrases": [
                                "Please drag your receipt inside the gray rectangle.",
                                "You can erase private information with the Eraser tool.",
                              ],
                            }
                          },
                          { // Receipt Info
                            "fieldType": "empty-bucket",
                            "fieldId": "vdf932fd",
                            "properties": {
                              "grid": {
                                "columns": "6"
                              },
                              "classes": "addMarginLeft",
                              "fields": [
                                {
                                  "fieldType": "receipt-information",
                                  "fieldId": "5mq1p6",
                                  "properties": {
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": 12
                                    },
                                    shouldDisplayFull: true,
                                    "texts": {
                                      "descText": `The following information must be visible:`,
                                      "descSubText": `Folded or scanned receipts or invoices will not be accepted`,
                                      "correct": "Valid",
                                      "notReadabe": "Hard to read",
                                      "folded": "Folded",
                                      "cropped": "Cut off"
                                    },
                                    "hideExamples": false,
                                    "differentExamples": true,
                                    "imgSrc": "assets/images/png/receipt-product-dach.png",
                                    "steps": [
                                      { // Retailer
                                        "text": "Retailer / Shop",
                                        "label": "A"
                                      },
                                      {
                                        "text": "Date and time of purchase",
                                        "label": "B"
                                      },
                                      {
                                        "text": "Campaign product and its price",
                                        "label": "C"
                                      },
                                      {
                                        "text": "Total sum of the receipt / invoice and applied VAT",
                                        "label": "D"
                                      },
                                      {
                                        "text": "Receipt / Invoice ID (not the bank transaction ID)",
                                        "label": "E"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "3",
                          "marginBottom": "30px"
                        },
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 12
                              },
                              text: "Send",
                              classes: "btn-dach",
                              "requires": ["recReupload"],
                              "action": {
                                "fn": "sendReceipt",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Product Re Upload
        "pageName": "product-reupload",
        isPublic: true,
        pageId: 120,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    },
                    {
                      query: "token",
                      state: "receiptToken"
                    },
                    {
                      query: "campaignId",
                      state: "campaignId"
                    },
                  ]
                }
              },
              { // 72 Hours check
                "fieldType": "checkReceiptToken",
                "fieldID": "dksnfsdkjf",
                properties: {
                  grid: { columns: 12 }
                }
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "40px"
                  },
                  "classes": "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Please upload your product photo here",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "25px"
                        },
                        text: "Please upload a photo of the back of your product.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Product Photo uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          {
                            "fieldType": "product-upload",
                            "fieldId": "prodPhoto",
                            "properties": {
                              texts: {
                                choose: 'UPLOAD',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG and PNG files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg and .png files are accepted.',
                                fileSizeLimit: 'This file is too big. Only files up to 10MB can be uploaded.'
                              },
                              "colorSchema": "#FB850F",
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "reUpload": true,
                              "endpoint": "/uploadProductPhoto",
                              "successAction": {
                                "fn": "goToPage",
                                "params": "thank-you-reupload"
                              }
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "3",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Send",
                              classes: "btn-dach",
                              "requires": ["prodPhoto"],
                              "action": {
                                "fn": "sendProductPhotos",
                                "params": "prodPhoto"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Iban reuploader
        "pageName": "iban-reupload",
        isPublic: true,
        pageId: 123,
        "requires": [
          1
        ],
        "sections": [
          { // Section
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "40px"
            },
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    },
                    {
                      query: "campaignId",
                      state: "campaignId"
                    },
                    {
                      query: "customerToken",
                      state: "customerToken"
                    },
                  ]
                }
              },
              {
                "fieldType": "iban-reupload",
                "fieldId": "ij3411"
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "classes": "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Provide bank details",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Please let us know to which account the refund amount should be transferred.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "bankRe",
                      "properties": {
                        "successAction": {
                          "fn": "goToPage",
                          "params": "thank-you-iban"
                        },
                        "endpoint": "/updateIBAN",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          marginBottom: "20px"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column",
                                "rowGap": "30px",
                                marginBottom: "50px"
                              },
                              "styles": {
                                "border": "1px solid #5D5FEF",
                                "box-shadow": "0px 0px 16px rgba(185, 185, 255, 0.65)",
                                "border-radius": "8px",
                                "padding": "40px"
                              },
                              "fields": [
                                { // First name
                                  "fieldId": "tp6zfb",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "firstname",
                                    "id": "firstname",
                                    "label": "Account holder first name",
                                    "validation": "required|ibanName",
                                    "labelOnTop": true,
                                    "placeholder": "Account holder first name",
                                    "text": "",
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                { // Last name
                                  "fieldId": "p55fao",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "lastname",
                                    "id": "lastname",
                                    "validation": "required|ibanName",
                                    "labelOnTop": true,
                                    "label": "Account holder last name",
                                    "placeholder": "Account holder last name",
                                    "text": "",
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                { // IBAN
                                  "fieldId": "h9arlr",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "iban",
                                    "id": "iban",
                                    "validation": "required|iban",
                                    "labelOnTop": true,
                                    "label": "IBAN",
                                    "placeholder": "Enter your IBAN number",
                                    "text": "",
                                    "isUpperCase": true,
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "3",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Send",
                              classes: "btn-dach",
                              requires: ['firstname', 'lastname', 'iban'],
                              "action": {
                                "fn": "sendForm",
                                "params": "bankRe"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Iban thank you page
        "pageName": "thank-you-iban",
        pageId: 124,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "50px"
            },
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  classes: "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Thank you",
                        classes: 'heading text-center'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "50px"
                        },
                        text: "Thank you for resubmitting your bank details. So there should now be a successful transfer.",
                        classes: 'text text-center'
                      }
                    },
                    { // Back to home Button
                      "fieldType": "button-component",
                      "fieldId": "but100",
                      properties: {
                        grid: {
                          columns: 8
                        },
                        text: "Back to campaign page",
                        classes: "btn-dach",
                        styles: {
                          "max-width": "600px"
                        },
                        "action": {
                          "anchor": true,
                          "link": "/"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Payout
        pageName: "payout",
        isPublic: true,
        pageId: 125,
        requires: [
          1
        ],
        "sections": [
          { // Section
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "40px"
            },
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "campaignId",
                      state: "campaignId"
                    },
                    {
                      query: "customerToken",
                      state: "customerToken"
                    },
                    {
                      query: "redirectionUrl",
                      state: "redirectionUrl"
                    }
                  ]
                }
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 8,
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "classes": "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Request your payout",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Please enter the information of your account to which the refund amount will be transferred.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "p4y0utF0RM",
                      "properties": {
                        "successAction": {
                          "fn": "popupAndRedirect",
                          "params": {
                            message: "Your IBAN information has been shared successfully!",
                            type: "success",
                            query: "redirectionUrl",
                            delay: 5000
                          }
                        },
                        "endpoint": "/submitIBAN",
                        "keepInState": ["redirectionUrl"],
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          marginBottom: "20px"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column",
                                "rowGap": "50px",
                                marginBottom: "50px"
                              },
                              "styles": {
                                "border": "1px solid #5D5FEF",
                                "box-shadow": "0px 0px 16px rgba(185, 185, 255, 0.65)",
                                "border-radius": "8px",
                                "padding": "40px"
                              },
                              "fields": [
                                { // First name
                                  "fieldId": "tp6zfb",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "firstname",
                                    "id": "firstname",
                                    "label": "Account holder first name",
                                    "validation": "required|ibanName",
                                    "labelOnTop": true,
                                    "placeholder": "Account holder first name",
                                    "text": "",
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                { // Last name
                                  "fieldId": "p55fao",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "lastname",
                                    "id": "lastname",
                                    "validation": "required|ibanName",
                                    "labelOnTop": true,
                                    "label": "Account holder last name",
                                    "placeholder": "Account holder last name",
                                    "text": "",
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                { // IBAN
                                  "fieldId": "h9arlr",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "iban",
                                    "id": "iban",
                                    "validation": "required|iban",
                                    "labelOnTop": true,
                                    "label": "IBAN",
                                    "placeholder": "Enter your IBAN number",
                                    "text": "",
                                    "isUpperCase": true,
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "3",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "SEND",
                              classes: "btn-primary",
                              requires: ['firstname', 'lastname', 'iban'],
                              "action": {
                                "fn": "sendForm",
                                "params": "p4y0utF0RM"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Login before IBAN + Address
        "pageName": "loginIbanAddress",
        isPublic: true,
        pageId: 200,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "50px",
              "marginTop": "50px",
            },
            "classes": "container",
            "sectionId": "loginSection",
            "fields": [
              { // Container
                "fieldId": "jakldklf",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                  },
                  fields: [
                    {
                      "fieldType": "query-string-to-state",
                      "fieldId": "query-string-to-state",
                      "properties": {
                        grid: { columns: 12 },
                        variables: [
                          { state: 'customerToken', query: "token" },
                          { state: 'receiptToken', query: "receiptToken" },
                          { state: 'campaignId', query: "campaignId", defaultValue: "joltx-de-immediate-second-user-journey" },
                          { state: 'referenceNumber', query: "referenceNumber" }
                        ]
                      }
                    },
                    {
                      "fieldType": "check-elgibility",
                      "fieldId": "check-elgibility",
                      "properties": {
                        grid: { columns: 12 },
                        endpoint: "/checkEligibility",
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Log in to your account",
                        classes: 'heading text-center'
                      }
                    },
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "login",
                      "properties": {
                        "successAction": {
                          "fn": "goToPage",
                          "params": "ibanAddress"
                        },
                        "endpoint": "/janrainLogin",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                              },
                              "fields": [
                                {
                                  "fieldType": "empty-bucket",
                                  "fieldId": "f934if",
                                  "properties": {
                                    "grid": {
                                      "columns": 8,
                                      "justifyContent": "center",
                                      "alignItems": "center",
                                      "flexDirection": "column",
                                      "rowGap": "30px",
                                      marginBottom: "50px"
                                    },
                                    "fields": [
                                      { // First name
                                        "fieldId": "tp6zfb",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "email",
                                          "id": "email",
                                          "label": "Email",
                                          "validation": "required|email",
                                          "labelOnTop": true,
                                          "placeholder": "Email",
                                          "text": "",
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      },
                                      { // Last name
                                        "fieldId": "p55fao",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "password",
                                          "id": "password",
                                          "validation": "required",
                                          "labelOnTop": true,
                                          "label": "Password",
                                          "placeholder": "Password",
                                          "inputType": "password",
                                          "text": "",
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      }
                                    ]
                                  }
                                },
                                { // Buttons
                                  "fieldId": "fu0923r",
                                  "fieldType": "empty-bucket",
                                  "properties": {
                                    "grid": {
                                      "columns": 6,
                                      "justifyContent": "center",
                                      "alignItems": "center",
                                      "flexDirection": "column"
                                    },
                                    fields: [
                                      { // SEND Button
                                        "fieldType": "button-component",
                                        "fieldId": "but100",
                                        properties: {
                                          grid: {
                                            columns: 3,
                                            marginBottom: "30px"
                                          },
                                          text: "Login",
                                          classes: "btn-dach",
                                          requires: ['email', 'password'],
                                          "action": [
                                            {
                                              "fn": "sendForm",
                                              "params": "login"
                                            }
                                          ]
                                        }
                                      },
                                      { // SEND Button
                                        "fieldType": "anchor",
                                        "fieldId": "but100",
                                        properties: {
                                          grid: {
                                            columns: 12
                                          },
                                          text: "Forgot your password?",
                                          url: 'https://www.for-me-online.de/passwort-vergessen',
                                          target: '_blank',
                                          styles: {
                                            "font-size": "18px",
                                            "line-height": "38px",
                                            "font-weight": "700",
                                            "color": "#2F80ED",
                                            "text-align": "center",
                                          }
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // IBAN + Address
        "pageName": "ibanAddress",
        isPublic: false,
        pageId: 201,
        "sections": [
          { // IBAN Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "50px",
            },
            "classes": "container",
            "sectionId": "ibanSection",
            "fields": [
              { // IBAN Bucket
                "fieldId": "ibanSection",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "borderTop": "46px solid #FB850F",
                    "paddingTop": "50px"
                  },
                  fields: [
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "lastStepForm",
                      "properties": {
                        "successAction": {
                          "fn": "goNextStep",
                          "params": "addressSection"
                        },
                        "endpoint": "/submitIban",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                              },
                              "fields": [
                                { // Heading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "15px"
                                    },
                                    text: "Provide bank details",
                                    classes: 'title text-left'
                                  }
                                },
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "30px"
                                    },
                                    text: "Please let us know to which account the refund amount should be transferred.",
                                    classes: 'subTitle text-left'
                                  }
                                },
                                {
                                  "fieldType": "empty-bucket",
                                  "fieldId": "f934if",
                                  "properties": {
                                    "grid": {
                                      "columns": 8,
                                      "justifyContent": "center",
                                      "alignItems": "center",
                                      "flexDirection": "column",
                                      "rowGap": "30px",
                                      marginBottom: "50px"
                                    },
                                    "fields": [
                                      { // First name
                                        "fieldId": "tp6zfb",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "firstname",
                                          "id": "firstname",
                                          "label": "Account holder first name",
                                          "validation": "required|ibanName",
                                          "labelOnTop": true,
                                          "placeholder": "Account holder first name",
                                          "text": "",
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      },
                                      { // Last name
                                        "fieldId": "p55fao",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "lastname",
                                          "id": "lastname",
                                          "validation": "required|ibanName",
                                          "labelOnTop": true,
                                          "label": "Account holder last name",
                                          "placeholder": "Account holder last name",
                                          "text": "",
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      },
                                      { // IBAN
                                        "fieldId": "h9arlr",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "iban",
                                          "id": "iban",
                                          "validation": "required|iban",
                                          "labelOnTop": true,
                                          "label": "IBAN",
                                          "placeholder": "Enter your IBAN number",
                                          "text": "",
                                          "isUpperCase": true,
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      }
                                    ]
                                  }
                                },
                                { // Buttons
                                  "fieldId": "fu0923r",
                                  "fieldType": "empty-bucket",
                                  "properties": {
                                    "grid": {
                                      "columns": 6
                                    },
                                    fields: [
                                      { // SEND Button
                                        "fieldType": "button-component",
                                        "fieldId": "but100",
                                        properties: {
                                          grid: {
                                            columns: 3
                                          },
                                          text: "Continue",
                                          classes: "btn-dach",
                                          requires: ['firstname', 'lastname', 'iban'],
                                          "action": [
                                            {
                                              "fn": "sendForm",
                                              "params": "lastStepForm"
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Address Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "50px",
              "marginTop": "50px",
            },
            "classes": "container",
            "sectionId": "addressSection",
            "requires": ['ibanSection'],
            "fields": [
              { // Container
                "fieldId": "jakldklf",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Enter address",
                        classes: 'heading text-left'
                      }
                    },
                    { // SubHeading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "To take part in this campaign we need your address. This will only be used for this campaign and will be deleted once you have completed your participation.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "addressForm",
                      "properties": {
                        "successAction": {
                          "fn": "goToPage",
                          "params": 'thank-you'
                        },
                        "endpoint": "/submitAddress",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          marginBottom: "20px"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 10,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "row",
                                "flexWrap": "wrap",
                                "rowGap": "30px",
                                "columnGap": "5px",
                                marginBottom: "50px"
                              },
                              "classes": "extra-form",
                              "styles": {
                                "padding": "40px"
                              },
                              "fields": [
                                { // First name
                                  "fieldId": "tp6zfb",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "firstName",
                                    "id": "firstName",
                                    "label": "Name",
                                    "validation": "required",
                                    "labelOnTop": true,
                                    "placeholder": "Name",
                                    "text": "",
                                    "grid": {
                                      "columns": 5
                                    }
                                  }
                                },
                                { // Last name
                                  "fieldId": "p55fao",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "lastName",
                                    "id": "lastName",
                                    "validation": "required",
                                    "labelOnTop": true,
                                    "label": "Last name",
                                    "placeholder": "Last name",
                                    "text": "",
                                    "grid": {
                                      "columns": 5
                                    }
                                  }
                                },
                                { // Street
                                  "fieldId": "p55fax",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "strasse",
                                    "id": "strasse",
                                    "validation": "required",
                                    "labelOnTop": true,
                                    "label": "Street",
                                    "placeholder": "Street",
                                    "text": "",
                                    "grid": {
                                      "columns": 5
                                    }
                                  }
                                },
                                { // House number
                                  "fieldId": "p55fay",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "hausnr",
                                    "id": "hausnr",
                                    "validation": "required",
                                    "labelOnTop": true,
                                    "label": "House number",
                                    "placeholder": "House number",
                                    "text": "",
                                    "grid": {
                                      "columns": 3
                                    }
                                  }
                                },
                                { // PLZ
                                  "fieldId": "p55faz",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "plz",
                                    "id": "plz",
                                    "validation": "required|plz:5",
                                    "labelOnTop": true,
                                    "label": "Zip Code",
                                    "placeholder": "Zip Code",
                                    "text": "",
                                    "grid": {
                                      "columns": 2
                                    }
                                  }
                                },
                                { // City
                                  "fieldId": "p55fam",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "ort",
                                    "id": "ort",
                                    "validation": "required",
                                    "labelOnTop": true,
                                    "label": "City",
                                    "placeholder": "City",
                                    "text": "",
                                    "grid": {
                                      "columns": 5
                                    }
                                  }
                                },
                                { // Country
                                  "fieldId": "p55fan",
                                  "fieldType": "select-input",
                                  "properties": {
                                    "name": "land",
                                    "id": "land",
                                    "validation": "required",
                                    "labelOnTop": true,
                                    "label": "Country",
                                    "placeholder": "Country",
                                    "text": "",
                                    "selectedValue": "de",
                                    "options": [
                                      {
                                        "value": "de",
                                        "text": "Deutschland"
                                      },
                                      {
                                        "value": "at",
                                        "text": "Österreich"
                                      },
                                      {
                                        "value": "ch",
                                        "text": "Schweiz"
                                      }
                                    ],
                                    "grid": {
                                      "columns": 5
                                    }
                                  }
                                },
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "3",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Send",
                              classes: "btn-dach",
                              "action": {
                                "fn": "sendForm",
                                "params": "addressForm"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Extra IBAN
        pageName: "extra/iban",
        isPublic: true,
        pageId: 126,
        requires: [
          1
        ],
        "sections": [
          {
            grid: {
              columns: 12,
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '50px',
              marginTop: '50px'
            },
            classes: 'container',
            sectionId: 'eanSection',
            fields: [
              {
                fieldId: 'eanContainer',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center'
                  },
                  fields: [
                    { // Heading
                      fieldType: "paragraph",
                      fieldId: "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Enter the barcode number of your product",
                        classes: 'heading text-left'
                      }
                    },
                    { // SubHeading
                      fieldType: "paragraph",
                      fieldId: "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "The 13-digit EAN number is usually located near the barcode on the product packaging.",
                        classes: 'subHeading text-left'
                      }
                    },
                    {
                      fieldId: '34n1nput',
                      fieldType: 'ean-input',
                      properties: {
                        grid: {
                          columns: 12
                        },
                        successAction: {
                          fn: "goNextStep",
                          params: "smsSection"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "50px",
              "marginTop": "50px",
            },
            "classes": "container",
            "sectionId": "smsSection",
            requires: ["34n1nput"],
            "fields": [
              { // Container
                "fieldId": "jakldklf",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Confirm your identity and claim your refund",
                        classes: 'heading text-left'
                      }
                    },
                    { // SubHeading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Verify your identity by entering your phone number here. It is only used once for verification and not for advertising purposes. After the action, your phone number will be deleted by us.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // SMS
                      "fieldType": "sms-verification",
                      "fieldId": "smsForm",
                      "properties": {
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": 12,
                          marginBottom: "50px"
                        },
                        "endpoint": "/mobileAuth",
                        "endpoint2": "/mobileVerification",
                        "inputWidth": "720px",
                        "successAction": {
                          "fn": "goNextStep",
                          "params": "ibanSection"
                        },
                        focusOnMount: false,
                        "colorSchema": "#FB850F",
                        "btnClasses": "btn-dach",
                        "texts": {
                          "phoneLabel": "Phone number",
                          "sendBtn": "Get SMS Code",
                          "codeLabel": "Verification code",
                          "codeSentLabel": "We sent you a verification code via sms",
                          "notReceived": "Didnt receive it?",
                          "clickHere": "Click here",
                          "changeNumber": "Change sms number",
                          "codeSendBtn": "Send",
                        },
                        options: [
                          {
                            name: "Germany",
                            code: "de",
                            phoneCode: "+49",
                          },
                          {
                            name: "Austria",
                            code: "at",
                            phoneCode: "+43",
                          },
                          {
                            name: "Switzerland",
                            code: "ch",
                            phoneCode: "+41",
                          }
                        ],
                      }
                    },
                  ]
                }
              }
            ]
          },
          { // Section
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "40px",
              "marginBottom": "40px",
              "marginLeft": "0",
              "marginRight": "0"
            },
            sectionId: "ibanSection",
            requires: ["34n1nput", "smsSection"],
            "fields": [
              { // Iframe Events
                "fieldType": "iframe-events",
                "fieldId": "uwxo2o2",
                properties: {
                  grid: {
                    columns: 12
                  }
                }
              },
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "campaignId",
                      state: "campaignId"
                    },
                    {
                      query: "customerToken",
                      state: "customerToken"
                    },
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    }
                  ]
                }
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 8,
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "classes": "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Provide bank details",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Please let us know to which account the refund amount should be transferred.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "lastStepIframe",
                      "properties": {
                        "successAction": {
                          "fn": "goToPage",
                          "params": "thank-you-iframe",
                          iframeEvents: [
                            {
                              event: "ibanSuccess",
                              value: "true"
                            },
                            {
                              event: "cashbackFormSend",
                              value: "success"
                            },
                          ],
                        },
                        requires: ["smsForm"],
                        "endpoint": "/submitIBAN",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          marginBottom: "20px"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column",
                                "rowGap": "50px",
                                marginBottom: "50px"
                              },
                              "styles": {
                                "box-shadow": "0px 0px 8px rgba(251, 133, 15, 0.65)",
                                "border-radius": "8px",
                                "padding": "40px"
                              },
                              "fields": [
                                { // First name
                                  "fieldId": "tp6zfb",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "firstname",
                                    "id": "firstname",
                                    "label": "Account holder first name",
                                    "validation": "required|ibanName",
                                    "labelOnTop": true,
                                    "placeholder": "Account holder first name",
                                    "text": "",
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                { // Last name
                                  "fieldId": "p55fao",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "lastname",
                                    "id": "lastname",
                                    "validation": "required|ibanName",
                                    "labelOnTop": true,
                                    "label": "Account holder last name",
                                    "placeholder": "Account holder last name",
                                    "text": "",
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                { // IBAN
                                  "fieldId": "h9arlr",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "iban",
                                    "id": "iban",
                                    "validation": "required|iban",
                                    "labelOnTop": true,
                                    "label": "IBAN",
                                    "placeholder": "Enter your IBAN number",
                                    "text": "",
                                    "isUpperCase": true,
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "3",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Next",
                              classes: "btn-dach",
                              requires: ['firstname', 'lastname', 'iban'],
                              "action": {
                                "fn": "sendForm",
                                "params": "lastStepIframe"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Extra Address
        pageName: "extra/address",
        isPublic: true,
        pageId: 127,
        requires: [
          1
        ],
        "sections": [
          {
            grid: {
              columns: 12,
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '50px',
              marginTop: '50px'
            },
            classes: 'container',
            sectionId: 'eanSection',
            fields: [
              {
                fieldId: 'eanContainer',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center'
                  },
                  fields: [
                    { // Heading
                      fieldType: "paragraph",
                      fieldId: "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Enter the barcode number of your product",
                        classes: 'heading text-left'
                      }
                    },
                    { // SubHeading
                      fieldType: "paragraph",
                      fieldId: "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "The 13-digit EAN number is usually located near the barcode on the product packaging.",
                        classes: 'subHeading text-left'
                      }
                    },
                    {
                      fieldId: '34n1nput',
                      fieldType: 'ean-input',
                      properties: {
                        grid: {
                          columns: 12
                        },
                        successAction: {
                          fn: "goNextStep",
                          params: "smsSection"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "50px",
              "marginTop": "50px",
            },
            "classes": "container",
            "sectionId": "smsSection",
            requires: ["34n1nput"],
            "fields": [
              { // Container
                "fieldId": "jakldklf",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Confirm your identity and claim your refund",
                        classes: 'heading text-left'
                      }
                    },
                    { // SubHeading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Verify your identity by entering your phone number here. It is only used once for verification and not for advertising purposes. After the action, your phone number will be deleted by us.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // SMS
                      "fieldType": "sms-verification",
                      "fieldId": "smsForm",
                      "properties": {
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": 12,
                          marginBottom: "50px"
                        },
                        "endpoint": "/mobileAuth",
                        "endpoint2": "/mobileVerification",
                        "inputWidth": "720px",
                        "successAction": {
                          "fn": "goNextStep",
                          "params": "addressSection"
                        },
                        focusOnMount: false,
                        "colorSchema": "#FB850F",
                        "btnClasses": "btn-dach",
                        "texts": {
                          "phoneLabel": "Phone number",
                          "sendBtn": "Get SMS Code",
                          "codeLabel": "Verification code",
                          "codeSentLabel": "We sent you a verification code via sms",
                          "notReceived": "Didnt receive it?",
                          "clickHere": "Click here",
                          "changeNumber": "Change sms number",
                          "codeSendBtn": "Send",
                        },
                        options: [
                          {
                            name: "Germany",
                            code: "de",
                            phoneCode: "+49",
                          },
                          {
                            name: "Austria",
                            code: "at",
                            phoneCode: "+43",
                          },
                          {
                            name: "Switzerland",
                            code: "ch",
                            phoneCode: "+41",
                          }
                        ],
                      }
                    },
                  ]
                }
              }
            ]
          },
          { // Section
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "40px",
              "marginBottom": "40px",
              "marginLeft": "0px",
              "marginRight": "0px"
            },
            sectionId: "addressSection",
            requires: ["34n1nput", "smsSection"],
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 8,
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "classes": "container",
                  fields: [
                    { // Iframe Events
                      "fieldType": "iframe-events",
                      "fieldId": "1uwxo2o2",
                      properties: {
                        grid: {
                          columns: 12
                        }
                      }
                    },
                    { // Query String
                      "fieldType": "query-string-to-state",
                      "fieldId": "1uwxo2o1",
                      properties: {
                        grid: {
                          columns: 0
                        },
                        variables: [
                          {
                            query: "campaignId",
                            state: "campaignId"
                          },
                          {
                            query: "customerToken",
                            state: "customerToken"
                          },
                          {
                            query: "referenceNumber",
                            state: "referenceNumber"
                          }
                        ]
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Enter address",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "To take part in this campaign we need your address. This will only be used for this campaign and will be deleted once you have completed your participation.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "lastStepIframe",
                      "properties": {
                        "successAction": {
                          "fn": "goToPage",
                          "params": "thank-you-iframe",
                          iframeEvents: [
                            {
                              event: "addressSuccess",
                              value: "true"
                            },
                            {
                              event: "cashbackFormSend",
                              value: "success"
                            },
                          ],
                        },
                        requires: ["smsForm"],
                        "endpoint": "/submitAddress",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          marginBottom: "20px"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 10,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "row",
                                "flexWrap": "wrap",
                                "rowGap": "30px",
                                "columnGap": "5px",
                                marginBottom: "50px"
                              },
                              "classes": "extra-form",
                              "styles": {
                                "box-shadow": "0px 0px 8px rgba(251, 133, 15, 0.65)",
                                "border-radius": "8px",
                                "padding": "40px"
                              },
                              "fields": [
                                { // First name
                                  "fieldId": "tp6zfb",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "firstName",
                                    "id": "firstName",
                                    "label": "Name",
                                    "validation": "required",
                                    "labelOnTop": true,
                                    "placeholder": "Name",
                                    "text": "",
                                    "grid": {
                                      "columns": 5
                                    }
                                  }
                                },
                                { // Last name
                                  "fieldId": "p55fao",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "lastName",
                                    "id": "lastName",
                                    "validation": "required",
                                    "labelOnTop": true,
                                    "label": "Last name",
                                    "placeholder": "Last name",
                                    "text": "",
                                    "grid": {
                                      "columns": 5
                                    }
                                  }
                                },
                                { // Street
                                  "fieldId": "p55fax",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "strasse",
                                    "id": "strasse",
                                    "validation": "required",
                                    "labelOnTop": true,
                                    "label": "Street",
                                    "placeholder": "Street",
                                    "text": "",
                                    "grid": {
                                      "columns": 5
                                    }
                                  }
                                },
                                { // House number
                                  "fieldId": "p55fay",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "hausnr",
                                    "id": "hausnr",
                                    "validation": "required",
                                    "labelOnTop": true,
                                    "label": "House number",
                                    "placeholder": "House number",
                                    "text": "",
                                    "grid": {
                                      "columns": 3
                                    }
                                  }
                                },
                                { // PLZ
                                  "fieldId": "p55faz",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "plz",
                                    "id": "plz",
                                    "validation": "required|plz:5",
                                    "labelOnTop": true,
                                    "label": "Zip Code",
                                    "placeholder": "Zip Code",
                                    "text": "",
                                    "grid": {
                                      "columns": 2
                                    }
                                  }
                                },
                                { // City
                                  "fieldId": "p55fam",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "ort",
                                    "id": "ort",
                                    "validation": "required",
                                    "labelOnTop": true,
                                    "label": "City",
                                    "placeholder": "City",
                                    "text": "",
                                    "grid": {
                                      "columns": 5
                                    }
                                  }
                                },
                                { // Country
                                  "fieldId": "p55fan",
                                  "fieldType": "select-input",
                                  "properties": {
                                    "name": "land",
                                    "id": "land",
                                    "validation": "required",
                                    "labelOnTop": true,
                                    "label": "Country",
                                    "placeholder": "Country",
                                    "text": "",
                                    "selectedValue": "de",
                                    "options": [
                                      {
                                        "value": "de",
                                        "text": "Deutschland"
                                      },
                                      {
                                        "value": "at",
                                        "text": "Österreich"
                                      },
                                      {
                                        "value": "ch",
                                        "text": "Schweiz"
                                      }
                                    ],
                                    "grid": {
                                      "columns": 5
                                    }
                                  }
                                },
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "3",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Send",
                              classes: "btn-dach",
                              "action": {
                                "fn": "sendForm",
                                "params": "lastStepIframe"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Extra IBAN + Address
        "pageName": "extra/ibanAddress",
        isPublic: true,
        pageId: 100123,
        "sections": [
          {
            grid: {
              columns: 12,
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '50px',
              marginTop: '50px'
            },
            classes: 'container',
            sectionId: 'eanSection',
            fields: [
              {
                fieldId: 'eanContainer',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center'
                  },
                  fields: [
                    { // Heading
                      fieldType: "paragraph",
                      fieldId: "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Enter the barcode number of your product",
                        classes: 'heading text-left'
                      }
                    },
                    { // SubHeading
                      fieldType: "paragraph",
                      fieldId: "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "The 13-digit EAN number is usually located near the barcode on the product packaging.",
                        classes: 'subHeading text-left'
                      }
                    },
                    {
                      fieldId: '34n1nput',
                      fieldType: 'ean-input',
                      properties: {
                        grid: {
                          columns: 12
                        },
                        successAction: {
                          fn: "goNextStep",
                          params: "smsSection"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "50px",
              "marginTop": "50px",
            },
            "classes": "container",
            "sectionId": "smsSection",
            requires: ["34n1nput"],
            "fields": [
              { // Container
                "fieldId": "jakldklf",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Confirm your identity and claim your refund",
                        classes: 'heading text-left'
                      }
                    },
                    { // SubHeading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Verify your identity by entering your phone number here. It is only used once for verification and not for advertising purposes. After the action, your phone number will be deleted by us.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // SMS
                      "fieldType": "sms-verification",
                      "fieldId": "smsForm",
                      "properties": {
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": 12,
                          marginBottom: "50px"
                        },
                        "endpoint": "/mobileAuth",
                        "endpoint2": "/mobileVerification",
                        "inputWidth": "720px",
                        "successAction": {
                          "fn": "goNextStep",
                          "params": "ibanSection"
                        },
                        focusOnMount: false,
                        "colorSchema": "#FB850F",
                        "btnClasses": "btn-dach",
                        "texts": {
                          "phoneLabel": "Phone number",
                          "sendBtn": "Get SMS Code",
                          "codeLabel": "Verification code",
                          "codeSentLabel": "We sent you a verification code via sms",
                          "notReceived": "Didnt receive it?",
                          "clickHere": "Click here",
                          "changeNumber": "Change sms number",
                          "codeSendBtn": "Send",
                        },
                        options: [
                          {
                            name: "Germany",
                            code: "de",
                            phoneCode: "+49",
                          },
                          {
                            name: "Austria",
                            code: "at",
                            phoneCode: "+43",
                          },
                          {
                            name: "Switzerland",
                            code: "ch",
                            phoneCode: "+41",
                          }
                        ],
                      }
                    },
                  ]
                }
              }
            ]
          },
          {
            "grid": {
              "columns": 0,
            },
            "sectionId": "uq3hy823aadsf",
            "fields": [
              { // Iframe Events
                "fieldType": "iframe-events",
                "fieldId": "uwxo2o2",
                properties: {
                  grid: {
                    columns: 12
                  }
                }
              },
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "campaignId",
                      state: "campaignId"
                    },
                    {
                      query: "customerToken",
                      state: "customerToken"
                    },
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    }
                  ]
                }
              }
            ]
          },
          { // IBAN Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "50px",
            },
            "classes": "container",
            "sectionId": "ibanSection",
            requires: ["34n1nput", "smsSection"],
            "fields": [
              { // IBAN Bucket
                "fieldId": "ibanBucket",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "borderTop": "46px solid #FB850F",
                    "paddingTop": "50px"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Provide bank details",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Please let us know to which account the refund amount should be transferred.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "ibanForm",
                      "properties": {
                        "successAction": {
                          "fn": "goNextStep",
                          "params": "addressSection"
                        },
                        requires: ["smsForm"],
                        "endpoint": "/submitIBAN",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          marginBottom: "20px"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column",
                                "rowGap": "50px",
                                marginBottom: "50px"
                              },
                              "styles": {
                                "box-shadow": "0px 0px 8px rgba(251, 133, 15, 0.65)",
                                "border-radius": "8px",
                                "padding": "40px"
                              },
                              "fields": [
                                { // First name
                                  "fieldId": "tp6zfb",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "firstname",
                                    "id": "firstname",
                                    "label": "Account holder first name",
                                    "validation": "required|ibanName",
                                    "labelOnTop": true,
                                    "placeholder": "Account holder first name",
                                    "text": "",
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                { // Last name
                                  "fieldId": "p55fao",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "lastname",
                                    "id": "lastname",
                                    "validation": "required|ibanName",
                                    "labelOnTop": true,
                                    "label": "Account holder last name",
                                    "placeholder": "Account holder last name",
                                    "text": "",
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                { // IBAN
                                  "fieldId": "h9arlr",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "iban",
                                    "id": "iban",
                                    "validation": "required|iban",
                                    "labelOnTop": true,
                                    "label": "IBAN",
                                    "placeholder": "Enter your IBAN number",
                                    "text": "",
                                    "isUpperCase": true,
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "3",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Next",
                              classes: "btn-dach",
                              requires: ['firstname', 'lastname', 'iban'],
                              "action": {
                                "fn": "sendForm",
                                "params": "ibanForm"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "50px",
              "marginTop": "50px",
            },
            "classes": "container",
            "sectionId": "addressSection",
            "requires": ["ibanSection"],
            "fields": [
              { // Container
                "fieldId": "jakldklf",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Enter address",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "To take part in this campaign we need your address. This will only be used for this campaign and will be deleted once you have completed your participation.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "lastStepIframe",
                      "properties": {
                        "successAction": {
                          "fn": "goToPage",
                          "params": "thank-you-iframe",
                          iframeEvents: [
                            {
                              event: "ibanSuccess",
                              value: "true"
                            },
                            {
                              event: "cashbackFormSend",
                              value: "success"
                            },
                          ]
                        },
                        "endpoint": "/submitAddress",
                        "requires": ["ibanForm"],
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          marginBottom: "20px"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 10,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "row",
                                "flexWrap": "wrap",
                                "rowGap": "30px",
                                "columnGap": "5px",
                                marginBottom: "50px"
                              },
                              "classes": "extra-form",
                              "styles": {
                                "box-shadow": "0px 0px 8px rgba(251, 133, 15, 0.65)",
                                "border-radius": "8px",
                                "padding": "40px"
                              },
                              "fields": [
                                { // First name
                                  "fieldId": "tp6zfb",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "firstName",
                                    "id": "firstName",
                                    "label": "Name",
                                    "validation": "required",
                                    "labelOnTop": true,
                                    "placeholder": "Name",
                                    "text": "",
                                    "grid": {
                                      "columns": 5
                                    }
                                  }
                                },
                                { // Last name
                                  "fieldId": "p55fao",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "lastName",
                                    "id": "lastName",
                                    "validation": "required",
                                    "labelOnTop": true,
                                    "label": "Last name",
                                    "placeholder": "Last name",
                                    "text": "",
                                    "grid": {
                                      "columns": 5
                                    }
                                  }
                                },
                                { // Street
                                  "fieldId": "p55fax",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "strasse",
                                    "id": "strasse",
                                    "validation": "required",
                                    "labelOnTop": true,
                                    "label": "Street",
                                    "placeholder": "Street",
                                    "text": "",
                                    "grid": {
                                      "columns": 5
                                    }
                                  }
                                },
                                { // House number
                                  "fieldId": "p55fay",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "hausnr",
                                    "id": "hausnr",
                                    "validation": "required",
                                    "labelOnTop": true,
                                    "label": "House number",
                                    "placeholder": "House number",
                                    "text": "",
                                    "grid": {
                                      "columns": 3
                                    }
                                  }
                                },
                                { // PLZ
                                  "fieldId": "p55faz",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "plz",
                                    "id": "plz",
                                    "validation": "required|plz:5",
                                    "labelOnTop": true,
                                    "label": "Zip Code",
                                    "placeholder": "Zip Code",
                                    "text": "",
                                    "grid": {
                                      "columns": 2
                                    }
                                  }
                                },
                                { // City
                                  "fieldId": "p55fam",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "ort",
                                    "id": "ort",
                                    "validation": "required",
                                    "labelOnTop": true,
                                    "label": "City",
                                    "placeholder": "City",
                                    "text": "",
                                    "grid": {
                                      "columns": 5
                                    }
                                  }
                                },
                                { // Country
                                  "fieldId": "p55fan",
                                  "fieldType": "select-input",
                                  "properties": {
                                    "name": "land",
                                    "id": "land",
                                    "validation": "required",
                                    "labelOnTop": true,
                                    "label": "Country",
                                    "placeholder": "Country",
                                    "text": "",
                                    "selectedValue": "de",
                                    "options": [
                                      {
                                        "value": "de",
                                        "text": "Deutschland"
                                      },
                                      {
                                        "value": "at",
                                        "text": "Österreich"
                                      },
                                      {
                                        "value": "ch",
                                        "text": "Schweiz"
                                      }
                                    ],
                                    "grid": {
                                      "columns": 5
                                    }
                                  }
                                },
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "3",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Send",
                              classes: "btn-dach",
                              "action": {
                                "fn": "sendForm",
                                "params": "lastStepIframe"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Extra 2FA
        pageName: "extra/2fa",
        isPublic: true,
        pageId: 12912,
        requires: [
          1
        ],
        sections: [
          {
            grid: {
              columns: 12,
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '50px',
              marginTop: '50px'
            },
            classes: 'container',
            sectionId: 'eanSection',
            fields: [
              {
                fieldId: 'eanContainer',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center'
                  },
                  fields: [
                    { // Heading
                      fieldType: "paragraph",
                      fieldId: "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Enter the barcode number of your product",
                        classes: 'heading text-left'
                      }
                    },
                    { // SubHeading
                      fieldType: "paragraph",
                      fieldId: "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "The 13-digit EAN number is usually located near the barcode on the product packaging.",
                        classes: 'subHeading text-left'
                      }
                    },
                    {
                      fieldId: '34n1nput',
                      fieldType: 'ean-input',
                      properties: {
                        grid: {
                          columns: 12
                        },
                        successAction: {
                          fn: "goNextStep",
                          params: "smsSection"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Steps and Header
            grid: {
              columns: 12,
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "50px",
              marginTop: "50px",
            },
            classes: "container",
            sectionId: "smsSection",
            requires: ["34n1nput"],
            fields: [
              { // Iframe Events
                fieldType: "iframe-events",
                fieldId: "uwxo2o2",
                properties: {
                  grid: {
                    columns: 12
                  }
                }
              },
              { // Query String
                fieldType: "query-string-to-state",
                fieldId: "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "campaignId",
                      state: "campaignId"
                    },
                    {
                      query: "customerToken",
                      state: "customerToken"
                    },
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    }
                  ]
                }
              },
              { // Container
                fieldId: "jakldklf",
                fieldType: "empty-bucket",
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: "center",
                    justifyContent: "center",
                  },
                  fields: [
                    { // Heading
                      fieldType: "paragraph",
                      fieldId: "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Confirm your identity and claim your refund",
                        classes: 'heading text-left'
                      }
                    },
                    { // SubHeading
                      fieldType: "paragraph",
                      fieldId: "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Verify your identity by entering your phone number here. It is only used once for verification and not for advertising purposes. After the action, your phone number will be deleted by us.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // SMS
                      fieldType: "sms-verification",
                      fieldId: "lastStepIframe",
                      properties: {
                        grid: {
                          alignItems: "center",
                          justifyContent: "center",
                          columns: 12,
                          marginBottom: "50px"
                        },
                        endpoint: "/mobileAuth",
                        endpoint2: "/mobileVerification",
                        inputWidth: "720px",
                        successAction: {
                          fn: "goToPage",
                          params: "thank-you-iframe",
                          iframeEvent: {
                            event: "ibanSuccess",
                            value: "true"
                          }
                        },
                        focusOnMount: false,
                        colorSchema: "#FB850F",
                        btnClasses: "btn-dach",
                        texts: {
                          phoneLabel: "Phone number",
                          sendBtn: "Get SMS Code",
                          codeLabel: "Verification code",
                          codeSentLabel: "We sent you a verification code via sms",
                          notReceived: "Didnt receive it?",
                          clickHere: "Click here",
                          changeNumber: "Change sms number",
                          codeSendBtn: "Send",
                        },
                        options: [
                          {
                            name: "Germany",
                            code: "de",
                            phoneCode: "+49",
                          },
                          {
                            name: "Austria",
                            code: "at",
                            phoneCode: "+43",
                          },
                          {
                            name: "Switzerland",
                            code: "ch",
                            phoneCode: "+41",
                          }
                        ],
                      }
                    },
                  ]
                }
              }
            ]
          }
        ]
      }
    ],
    "header": {
      "fieldType": "navbar",
      "fieldId": "cbx34t",
      "properties": {
        "grid": {
          "columns": 12
        },
        "favicon": {
          "imageSource": "assets/images/svg/pglogo.svg"
        },
        "backgroundColor": "#fff",
        "color": "#FFE082",
        "logo": {
          "imageSource": "assets/images/png/formeLogo.png",
          "width": 75,
          "alternateText": "Brand Logo",
          "paddingTop": "30px",
          "url":"https://www.for-me-online.de/"
        },
        "fields": [
          {
            fieldType: "dynamic-terms-anchor",
            fieldId: "t3rms4ndc0nd1ti0ns"
          }
        ],
        hideLogoOnPages: [
          "/extra/iban",
          "/extra/address",
          "/extra/ibanAddress",
          "/extra/2fa",
          "/thank-you-iframe"
        ],
        showLinksOnPages: [
          "/extra/iban",
          "/extra/address",
          "/extra/ibanAddress",
          "/extra/2fa",
          "/thank-you-iframe"
        ],
        showLangSwitcherOnPages: [
          "/receipt-reupload",
          "/product-reupload",
          "/thank-you-reupload",
          "/iban-reupload",
          "/thank-you-iban",
          "/extra/iban",
          "/extra/address",
          "/extra/ibanAddress",
          "/extra/2fa",
          "/thank-you-iframe"
        ],
        "meta": [
          { "name": "title", "content": "PG" },
          {
            "name": "description",
            "content": ``
          },
          {
            "name": "keywords",
            "content": `PG`
          }
        ]
      }
    },
    "footer": {
    },
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'An error occured.',
    isActive: false
  },
  customerToken: '',
  receiptToken: '',
  campaignId: '',
  formData: new FormData(),
  currentSection: 'loginSection',
  consumerId: '',
}
