import paragraph from "@/components/renderers/paragraph.vue"
import squareImage from "@/components/renderers/square-image.vue"
import alert from "@/components/renderers/alert.vue"
import keyVisual from "@/components/renderers/key-visual.vue"
import navbar from "@/components/renderers/navbar.vue"
import anchor from "@/components/renderers/anchor.vue"
import receiptProcessor from "@/components/renderers/receipt-processor.vue"
import productUpload from "@/components/renderers/product-upload.vue"
import formComponent from "@/components/renderers/form-component.vue"
import textInput from "@/components/renderers/text-input.vue"
import stepCard from "@/components/renderers/step-card.vue"
import selectInput from "@/components/renderers/select-input.vue"
import radioInput from "@/components/renderers/radio-input.vue"
import dateInput from "@/components/renderers/date-input.vue"
import buttonComponent from "@/components/renderers/button-component.vue"
import productSelection from "@/components/renderers/ProductSelection/index.vue"
import emptyBucket from "@/components/renderers/empty-bucket.vue"
import receiptInformation from "@/components/renderers/receipt-information.vue"
import receiptExamples from "@/components/renderers/receipt-examples.vue"
import smsVerification from "@/components/renderers/SmsVerification/sms-verification.vue"
import checkbox from "@/components/renderers/checkbox.vue"
import gtinComponent from "@/components/renderers/gtin-component.vue"
import footerComponent from "@/components/renderers/footer-component.vue"
import question from "@/components/renderers/question.vue"
import reclaimBucket from "@/components/renderers/reclaim-bucket.vue"
import dataLayerEvent from "@/components/renderers/datalayer-event.vue"
import refreshToken from "@/components/renderers/refresh-token.vue"
import queryStringToState from "@/components/renderers/query-string-to-state.vue"
import barcodeScanner from "@/components/renderers/BarcodeScanner/index.vue"
import ibanReupload from "@/components/renderers/iban-reupload.vue"
import checkReceiptToken from "@/components/renderers/checkReceiptToken.vue"
import participation from "@/components/renderers/participation.vue"
import doi from "@/components/renderers/doi.vue"
import progressBar from "@/components/renderers/progress-bar.vue"
import clipboard from "@/components/renderers/clipboard.vue"
import survey from "@/components/renderers/survey.vue"
import checkElgibility from "@/components/renderers/check-elgibility.vue"
import heading from "@/components/renderers/heading.vue"
import list from "@/components/renderers/list.vue"
import uniqueCode from "@/components/renderers/unique-code.vue"
import activeCampaigns from "@/components/renderers/active-campaigns.vue"
import iframeEvents from "@/components/renderers/iframe-events.vue"
import dynamicSelectInput from "@/components/renderers/dynamic-select-input.vue"
import fileInput from "@/components/renderers/file-input.vue"
import dynamicTermsAnchor from "@/components/renderers/dynamic-terms-anchor.vue"
import wpbotSurvey from "@/components/renderers/whatsapp-bot-survey.vue"
import codeComponent from "@/components/renderers/code-component.vue"
import eanInput from "@/components/renderers/ean-input.vue"

export default [
  barcodeScanner,
  refreshToken,
  dataLayerEvent,
  reclaimBucket,
  ibanReupload,
  question,
  receiptInformation,
  receiptExamples,
  smsVerification,
  paragraph,
  squareImage,
  alert,
  emptyBucket,
  keyVisual,
  navbar,
  anchor,
  receiptProcessor,
  productUpload,
  formComponent,
  textInput,
  stepCard,
  selectInput,
  radioInput,
  dateInput,
  buttonComponent,
  productSelection,
  checkbox,
  gtinComponent,
  footerComponent,
  queryStringToState,
  checkReceiptToken,
  participation,
  doi,
  progressBar,
  clipboard,
  survey,
  checkElgibility,
  heading,
  list,
  uniqueCode,
  activeCampaigns,
  iframeEvents,
  dynamicSelectInput,
  fileInput,
  dynamicTermsAnchor,
  wpbotSurvey,
  codeComponent,
  eanInput
];
