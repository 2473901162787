
export default {
  isEditMode: true,
  pageProperties: {
    testing: false,
    "pages": [
      { // Unique Code page
        "pageName": "wpbot/dentistCode",
        "locale": "tr",
        isPublic: true,
        "pageId": 4071,
        "requires": [
          1
        ],
        "sections": [
          { // Section
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "40px"
            },
            "fields": [
              {
                "fieldType": "query-string-to-state",
                "fieldId": "query-string-to-state",
                "properties": {
                  grid: { columns: 12 },
                  variables: [
                    { state: 'customerToken', query: "customerToken" },
                    { state: 'campaignId', query: "campaignId" },
                    { state: 'receiptToken', query: "receiptToken" }
                  ]
                }
              },
              {
                "fieldType": "empty-bucket",
                "fieldId": "fw90342ol",
                "properties": {
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": 8,
                    "marginBottom": "50px"
                  },
                  "fields": [
                    {
                      "fieldType": "code-component",
                      "fieldId": "kod",
                      "properties": {
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "successAction": {
                          "fn": "goToPage",
                          "params": "wpbot/payout"
                        },
                        barcodeImg: '/assets/wpbotTr/barcode.jpg',
                        setCode: true
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Payout
        pageName: "wpbot/payout",
        isPublic: true,
        pageId: 125,
        requires: [
          1
        ],
        "sections": [
          { // Section
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "40px"
            },
            "fields": [
              {
                "fieldType": "query-string-to-state",
                "fieldId": "query-string-to-state",
                "properties": {
                  grid: { columns: 12 },
                  variables: [
                    { state: 'customerToken', query: "customerToken" },
                    { state: 'campaignId', query: "campaignId" },
                    { state: 'receiptToken', query: "receiptToken" }
                  ]
                }
              },
              {
                "fieldType": "check-elgibility",
                "fieldId": "check-elgibility",
                "properties": {
                  grid: { columns: 12 },
                  endpoint: "/checkEligibility",
                }
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 8,
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "classes": "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Request your payout",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Please enter the information of your account to which the refund amount will be transferred.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "ibanForm",
                      "properties": {
                        "successAction": {
                          "fn": "sendWpbotSurvey",
                          "params": ""
                        },
                        "endpoint": "/submitIBAN",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          marginBottom: "20px"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column",
                                "rowGap": "50px",
                                marginBottom: "50px"
                              },
                              "styles": {
                                "border": "1px solid #5D5FEF",
                                "box-shadow": "0px 0px 16px rgba(185, 185, 255, 0.65)",
                                "border-radius": "8px",
                                "padding": "40px"
                              },
                              "fields": [
                                { // First name
                                  "fieldId": "tp6zfb",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "firstname",
                                    "id": "firstname",
                                    "label": "Account holder first name",
                                    "validation": "required|ibanNameWpbot",
                                    "labelOnTop": true,
                                    "placeholder": "Account holder first name",
                                    "text": "",
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                { // Last name
                                  "fieldId": "p55fao",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "lastname",
                                    "id": "lastname",
                                    "validation": "required|ibanName",
                                    "labelOnTop": true,
                                    "label": "Account holder last name",
                                    "placeholder": "Account holder last name",
                                    "text": "",
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                { // IBAN
                                  "fieldId": "h9arlr",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "iban",
                                    "id": "iban",
                                    "validation": "required|iban:TR",
                                    "labelOnTop": true,
                                    "label": "IBAN",
                                    "placeholder": "Enter your IBAN number",
                                    "text": "",
                                    "ibanPrepend": "TR",
                                    "isUpperCase": true,
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                {
                                  fieldId: "wpb0tsurv3y",
                                  fieldType: "whatsapp-bot-survey",
                                  properties: {
                                    grid: {
                                      columns: 12
                                    },
                                    successAction: {
                                      fn: "goToPage",
                                      params: "thank-you"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "3",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "SEND",
                              classes: "btn-primary",
                              requires: ['firstname', 'lastname', 'iban'],
                              "action": {
                                "fn": "sendForm",
                                "params": "ibanForm"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // 404
        "pageName": "error-page",
        pageId: 34646,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "secondaryErrorBucket",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "60px",
                    "marginBottom": "60px"
                  },
                  classes: "maxWidthOverride transparentBg errorPageMarginTop",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "oopsErrorPage",
                      "properties": {
                        "text": `Hay Aksi!`,
                        classes: "text-center header-text",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginTop": "50px",
                          "marginBottom": "40px",
                        }
                      }
                    },
                    { // Key visual
                      "fieldType": "key-visual",
                      "fieldId": "key100",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px",
                        },
                        styles: {
                          "padding": '0 0 0 0;',
                          "max-width": '400px'
                        },
                        noContainer: true,
                        desktopImageUrl: "assets/images/svg/error-image.svg",
                        tabletImageUrl: "assets/images/svg/error-image.svg",
                        mobileImageUrl: "assets/images/svg/error-image.svg"
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "errorSubHeading",
                      "properties": {
                        "text": `Oops, something went wrong. Please try again.`,
                        "classes": 'text text-center subheader-text',
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginTop": "10px",
                          "marginBottom": "50px"
                        }
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "82358325",
                      "properties": {
                        "grid": {
                          "columns": "5",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "button-component",
                            "fieldId": "errorBackButton",
                            "properties": {
                              "text": "Back to campaign page",
                              "grid": {
                                "columns": "12",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              classes: "btn-primary",
                              "disabled": false,
                              "action": {
                                "anchor": true,
                                "link": "https://www.kadinlarbilir.com/Ariel%20Oxi%20Para%20Iade"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Expired page
        "pageName": "expired-page",
        pageId: 3412646,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "secondaryErrorBucket",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  classes: "maxWidthOverride transparentBg errorPageMarginTop",
                  fields: [
                    { // Key visual
                      "fieldType": "key-visual",
                      "fieldId": "key100",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px",
                        },
                        styles: {
                          "padding": '0 0 0 0;',
                        },
                        noContainer: true,
                        desktopImageUrl: "assets/images/png/expired-page-tr.png",
                        tabletImageUrl: "assets/images/png/expired-page-tr.png",
                        mobileImageUrl: "assets/images/png/expired-page-tr.png"
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "errorSubHeading",
                      "properties": {
                        "text": `Our campaign has ended. See you in other campaigns :)`,
                        "classes": 'text text-center subheader-text',
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginTop": "10px",
                          "marginBottom": "80px"
                        }
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "82358325",
                      "properties": {
                        "grid": {
                          "columns": "5",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "button-component",
                            "fieldId": "errorBackButton",
                            "properties": {
                              "text": "Back to campaign page",
                              "grid": {
                                "columns": "12",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              classes: "btn-primary",
                              "disabled": false,
                              "action": {
                                "anchor": true,
                                "link": "https://www.kadinlarbilir.com/Ariel%20Oxi%20Para%20Iade"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Thank you
        "pageName": "thank-you",
        pageId: 34673457,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "classes": "container",
            "fields": [
              { // Thank you Bucket
                "fieldId": "thankBox",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "50px"
                  },
                  fields: [
                    { // Key visual
                      "fieldType": "key-visual",
                      "fieldId": "key100",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        styles: {
                          // "padding": '0 0 0 0'
                        },
                        noContainer: true,
                        desktopImageUrl: "assets/images/banner/banner.png",
                        tabletImageUrl: "assets/images/banner/banner.png",
                        mobileImageUrl: "assets/images/banner/banner.png"
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "30px"
                        },
                        text: "Banka bilgileriniz bize ulaştı, para iadenizi 4-6 hafta içerisinde hesabınıza aktaracağız.",
                        classes: 'text text-center'
                      }
                    }
                  ]
                }
              },
              {
                "fieldType": "empty-bucket",
                "fieldId": "82358325",
                "properties": {
                  "grid": {
                    "columns": "5",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "50px"
                  },
                  "fields": [
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Back to campaign page",
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        classes: "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.kadinlarbilir.com"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt Re Upload
        "pageName": "wpbot/receipt-reupload",
        isPublic: true,
        pageId: 119,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
            },
            "sectionId": "fu30zq",
            "classes": "container",
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    },
                    {
                      query: "token",
                      state: "receiptToken"
                    },
                    {
                      query: "campaignId",
                      state: "campaignId"
                    },
                  ]
                }
              },
              { // 72 Hours check
                "fieldType": "checkReceiptToken",
                "fieldID": "dksnfsdkjf",
                properties: {
                  grid: { columns: 12 }
                }
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "40px"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Upload a receipt",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "25px"
                        },
                        text: "In this step, please upload the proof of purchase of your products.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Receipt uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "space-around",
                          "marginBottom": "60px",
                          "marginTop": "50px"
                        },
                        "classes": "receipt-bucket",
                        "fields": [
                          { // Recipt upload
                            "fieldType": "receipt-processor",
                            "fieldId": "recReupload",
                            "properties": {
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "successAction": {
                                "fn": "goToPage",
                                "params": "thank-you"
                              },
                              texts: {
                                choose: 'UPLOAD',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG, PNG and PDF files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.',
                                fileSizeLimit: 'This file is too big. Only files up to 10MB can be uploaded.'
                              },
                              editTexts: {
                                crop: "Crop",
                                erase: "Erase",
                                drag: "Drag",
                                zoomIn: "Zoom in",
                                zoomOut: "Zoom out"
                              },
                              reUpload: true,
                              colorSchema: "#8183F0",
                              postbillCampaignId: "wpbot-pgtr",
                              "phrases": [
                                "Your receipt is now being loaded and processed.",
                                "The receipt will be processed automatically. This may take a minute. Please be patient.",
                                "If you get an error message, please get a better version of the receipt and upload it again."
                              ],
                              "cornerDetectionPhrases": [
                                "Please drag your receipt inside the gray rectangle.",
                                "You can erase private information with the Eraser tool.",
                              ],
                            }
                          },
                          { // Receipt Info
                            "fieldType": "empty-bucket",
                            "fieldId": "vdf932fd",
                            "properties": {
                              "grid": {
                                "columns": "6"
                              },
                              "classes": "addMarginLeft",
                              "fields": [
                                {
                                  "fieldType": "receipt-information",
                                  "fieldId": "5mq1p6",
                                  "properties": {
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": 12
                                    },
                                    shouldDisplayFull: true,
                                    "texts": {
                                      "descText": `The following information must be visible:`,
                                      "descSubText": `Folded or scanned receipts or invoices will not be accepted`,
                                      "correct": "Valid",
                                      "notReadabe": "Hard to read",
                                      "folded": "Folded",
                                      "cropped": "Cut off"
                                    },
                                    "hideExamples": false,
                                    "steps": [
                                      { // Retailer
                                        "text": "Retailer / Shop",
                                        "label": "A"
                                      },
                                      {
                                        "text": "Date and time of purchase",
                                        "label": "B"
                                      },
                                      {
                                        "text": "Campaign product and its price",
                                        "label": "C"
                                      },
                                      {
                                        "text": "Total sum of the receipt / invoice and applied VAT",
                                        "label": "D"
                                      },
                                      {
                                        "text": "Receipt / Invoice ID (not the bank transaction ID)",
                                        "label": "E"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "3",
                          "marginBottom": "30px"
                        },
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 12
                              },
                              text: "SEND",
                              classes: "btn-primary",
                              "requires": ["recReupload"],
                              "action": {
                                "fn": "sendReceipt",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Iban reuploader
        "pageName": "wpbot/iban-reupload",
        isPublic: true,
        pageId: 123,
        "requires": [
          1
        ],
        "sections": [
          { // Section
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "40px"
            },
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    },
                    {
                      query: "campaignId",
                      state: "campaignId"
                    },
                    {
                      query: "customerToken",
                      state: "customerToken"
                    },
                  ]
                }
              },
              {
                "fieldType": "iban-reupload",
                "fieldId": "ij3411"
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "classes": "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Provide bank details",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Please let us know to which account the refund amount should be transferred.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "bankRe",
                      "properties": {
                        "successAction": {
                          "fn": "goToPage",
                          "params": "thank-you-iban"
                        },
                        "endpoint": "/updateIBAN",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          marginBottom: "20px"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column",
                                "rowGap": "50px",
                                marginBottom: "50px"
                              },
                              "styles": {
                                "border": "1px solid #5D5FEF",
                                "box-shadow": "0px 0px 16px rgba(185, 185, 255, 0.65)",
                                "border-radius": "8px",
                                "padding": "40px"
                              },
                              "fields": [
                                { // First name
                                  "fieldId": "tp6zfb",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "firstname",
                                    "id": "firstname",
                                    "label": "Account holder first name",
                                    "validation": "required|ibanName",
                                    "labelOnTop": true,
                                    "placeholder": "Account holder first name",
                                    "text": "",
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                { // Last name
                                  "fieldId": "p55fao",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "lastname",
                                    "id": "lastname",
                                    "validation": "required|ibanName",
                                    "labelOnTop": true,
                                    "label": "Account holder last name",
                                    "placeholder": "Account holder last name",
                                    "text": "",
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                },
                                { // IBAN
                                  "fieldId": "h9arlr",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "iban",
                                    "id": "iban",
                                    "validation": "required|iban:TR",
                                    "labelOnTop": true,
                                    "label": "IBAN",
                                    "placeholder": "Enter your IBAN number",
                                    "text": "",
                                    "ibanPrepend": "TR",
                                    "isUpperCase": true,
                                    "grid": {
                                      "columns": 12
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "3",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // SEND Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "SEND",
                              classes: "btn-primary",
                              requires: ['firstname', 'lastname', 'iban'],
                              "action": {
                                "fn": "sendForm",
                                "params": "bankRe"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Iban thank you page
        "pageName": "thank-you-iban",
        pageId: 124,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "50px"
            },
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  classes: "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Thank you",
                        classes: 'heading text-center'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "50px"
                        },
                        text: "Thank you for resubmitting your bank details. So there should now be a successful transfer.",
                        classes: 'text text-center'
                      }
                    },
                    { // Back to home Button
                      "fieldType": "button-component",
                      "fieldId": "but100",
                      properties: {
                        grid: {
                          columns: 8
                        },
                        text: "Back to campaign page",
                        classes: "btn-primary",
                        styles: {
                          "max-width": "600px"
                        },
                        "action": {
                          "anchor": true,
                          "link": "/"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ],
    "header": {
      "fieldType": "navbar",
      "fieldId": "cbx34t",
      "properties": {
        "grid": {
          "columns": 12
        },
        "favicon": {
          "imageSource": "assets/images/svg/pglogo.svg"
        },
        "backgroundColor": "#fff",
        "color": "#FFE082",
        "logo": {
          "imageSource": "assets/images/png/kadinlarLogo.png",
          "width": 75,
          "alternateText": "Brand Logo",
          "paddingTop": "30px"
        },
        "fields": [
        ],
        "meta": [
          { "name": "title", "content": "PG" },
          {
            "name": "description",
            "content": ``
          },
          {
            "name": "keywords",
            "content": `PG`
          }
        ]
      }
    },
    "footer": {
    },
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'An error occured.',
    isActive: false
  },
  customerToken: '',
  receiptToken: '',
  campaignId: '',
  formData: new FormData(),
  currentSection: 'loginSection',
  country: 'TR',
  redirectionUrl: '',
  uniqueCode: ''
}
