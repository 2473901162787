<template>
  <span></span>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "check-elgibility",
  props: {
    endpoint: String
  },
  data() {
    return {
      timeout: undefined
    }
  },
  mounted() {
    if (this.$store.state.country == 'GR' || this.$store.state.country == 'RO') {
      setTimeout(() => {
        if (this.$auth.isAuthenticated) {
          this.checkEligibilityAfterLogin({ email: this.$auth.user.email })
          .then (() => {
            this.goToPage('userData', this)
          })
          .catch(() => {
              this.goToPage('expired-page', this)
            }).finally(() => {
              this.$store.commit('setLoading', false)
            })
        }
      }, 1000);
    }
    this.timeout = setTimeout(() => {
      this.checkEligibility({ endpoint: this.endpoint })
        .catch((e) => {
          if(e.goToExpired){ 
            this.goToPage('expired-page', this)
          } else if (!this.isTester) {
            this.goToPage('error-page', this);
          }
        });
    }, 100);
  },
  destroyed() {
    clearTimeout(this.timeout);
  },
  methods: {
    ...mapActions(['checkEligibility', 'checkEligibilityAfterLogin'])
  }
};
</script>
