<template>
  <div
    class="ean-input-wrapper"
    :class="{ 'done': done }"
  >
    <ValidationObserver ref="eanInputForm">
      <form>
        <div class="d-flex flex-column justify-content-center">
          <div class="col-12 col-sm-4">
            <TextInput
              v-model="ean"
              name="ean"
              id="ean"
              placeholder="0000000000000"
              validation="required|ean"
            />
          </div>
          <div class="col-12 col-sm-3 d-flex align-self-center mt-4">
            <ButtonComponent
              text="Next"
              class="btn-dach"
              @click.native="onSearchProductClicked"
            />
          </div>
        </div>
      </form>
    </ValidationObserver>
    <div
      v-if="!!selectedProducts.length"
      class="d-flex flex-column"
    >
      <div
        v-for="(p, idx) in selectedProducts"
        :key="idx"
        class="product"
      >
        <div class="product-details">
          <span><b>{{ p.name }}</b></span>
          <span>EAN: {{ p.ean }}</span>
        </div>
        <div class="product-actions">
          <span class="product-actions__count">{{ p.count }}</span>
          <span @click="removeProduct(p)">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 19.5C6 20.0304 6.21071 20.5391 6.58579 20.9142C6.96086 21.2893 7.46957 21.5 8 21.5H16C16.5304 21.5 17.0391 21.2893 17.4142 20.9142C17.7893 20.5391 18 20.0304 18 19.5V7.5H6V19.5ZM8 9.5H16V19.5H8V9.5ZM15.5 4.5L14.5 3.5H9.5L8.5 4.5H5V6.5H19V4.5H15.5Z"
                fill="#DF1B25"
              />
            </svg>
          </span>
        </div>
      </div>
      <div class="col-12 col-sm-3 d-flex align-self-center mt-4">
        <ButtonComponent
          text="Send"
          class="btn-dach"
          @click.native="onSubmitProductsClicked"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/renderers/text-input'
import ButtonComponent from '@/components/renderers/button-component'
import { mapActions } from 'vuex'

export default {
  name: 'EanInput',
  components: {
    TextInput,
    ButtonComponent
  },
  data () {
    return {
      ean: '',
      selectedProducts: [],
      done: false
    }
  },
  props: {
    fieldId: {
      required: true,
      type: String
    },
    successAction: {
      required: true,
      type: Object
    }
  },
  methods: {
    ...mapActions(['searchProduct', 'submitProducts', 'handleError']),
    async onSearchProductClicked () {
      if (!this.done) {
        const valid = await this.$refs.eanInputForm.validate()
        if (valid) {
          try {
            const res = await this.searchProduct({ barcode: this.ean })
            const existing = this.selectedProducts.find(p => p.ean === this.ean)
            if (existing) existing.count++
            else {
              const product = { ...res.product, count: 1 }
              this.selectedProducts.push(product)
            }
          } catch (err) {
            const customMsg = err.response?.data?.message?.message || ''
            const code = err.response.status
            this.handleError({ customMsg, code })
          }
        }
      }
    },
    async onSubmitProductsClicked () {
      if (!this.done) {
        try {
          await this.submitProducts({ products: this.selectedProducts })
        } catch (err) {
          const customMsg = err.response?.data?.message?.message || ''
          const code = err.response.status
          this.handleError({ customMsg, code })
        } finally {
          this.checkStatus()
        }
      }
    },
    removeProduct(product) {
      const i = this.selectedProducts.indexOf(product)
      this.selectedProducts.splice(i, 1)
      this.checkStatus()
    },
    checkStatus() {
      this.done = !!this.selectedProducts.length
      this.$eventHub.$emit("done-event-trigger", {
        parent: this,
        status: this.done
      })

      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: this.done
      })

      if (this.done) {
        const fn = this.successAction.fn
        const params = this.successAction.params
        return this[fn](params, this)
      } else {
        this.scrollTo('#eanSection')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ean-input-wrapper {
  padding-bottom: 50px;
  border-bottom: 46px solid #FB850F;

  .product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    padding: 1.6rem;
    border: 1px solid #FB850F;
    border-radius: 8px;
    background-color: #FFF;

    &-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      row-gap: 0.4rem;
    }

    &-actions {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 1.6rem;

      &__count {
        padding: 1rem 2rem;
        background-color: #EBEBEB;
        border: 1px solid #FB850F;
        border-radius: 8px;
      }
    }
  }
}

.done {
  opacity: .5;
  pointer-events: none;
}
</style>