<template>
  <div
    class="sms-wrapper row"
    :style="cssVars"
    :id="fieldId"
  >
    <ValidationObserver
      id="numberForm" 
      ref="numberForm"
      class="col-12 col-md-6"
      :class="{ 'active': state == 'number' }"
    >
      <div class="inner">
        <div class="label">
          {{ $t(texts.phoneLabel) }}
        </div>
        <div class="inputWrapper">
          <div
            ref="selectWrapper"
            class="col-lg-4"
          >
            <v-select
              :options="options"
              label="name"
              value="code"
              :clearable="false"
              :searchable="false"
              v-model="selectedCountry"
            >
              <template
                #option="{ code, phoneCode }"
              >
                <div class="country-item">
                  <div>
                    <country-flag
                      :country="code"
                      size="normal"
                    />
                  </div>
                  <div>
                    <b>{{ phoneCode }}</b>
                  </div>
                </div>
              </template>
              <template
                #selected-option="{ code, phoneCode }"
              >
                <div style="display: flex; align-items: center; column-gap: 10px;">
                  <div>
                    <country-flag
                      :country="code"
                      size="normal"
                    />
                  </div>
                  <div><b>{{ phoneCode }}</b></div>
                </div>
              </template>
            </v-select>
          </div>
          <div class="col-lg-8">
            <textInput
              id="phoneNumber"
              v-model="tel"
              name="phoneNumber"
              class="phoneNumber col-lg-12"
              label=""
              placeholder="123456789"
              :validation="`required|phoneNumber`"
              :label-on-top="false"
              :no-label="true"
              :styles="inputStyles"
              :slide-up="tel != ''"
            />
          </div>
        </div>
        <div class="btn-wrapper">
          <button-component
            :text="texts.sendBtn"
            :classes="btnClasses"
            :requires="['phoneNumber']"
            :action="{
              fn: 'sendSms',
              params: ''
            }"
          />
        </div>
      </div>
    </ValidationObserver>
    <div v-if="step1Status" class="sms-step sms-step-1">
      <img :src="require('@/assets/images/png/sms-step.png')" class="sms-step-image"/>
    </div>
    <ValidationObserver
      ref="codeForm"
      id="codeForm"
      class="col-12 col-md-6"
      :class="{ 'active': state == 'code' }"
    >
      <div class="inner">
        <div class="label">
          {{ $t(texts.codeLabel) }}
        </div>
        <v-otp-input
          input-classes="otp-input"
          :num-inputs="4"
          separator=""
          :should-auto-focus="false"
          @on-change="handleOnChange"
          @on-complete="handleOnChange"
        />
        <div
          class="label"
        >
          {{ $t(texts.codeSentLabel) }}
        </div>
        <div
          class="label"
        >
          {{ $t(texts.notReceived) }} <a @click="goToNumberSection">{{ $t(texts.clickHere) }}</a>
        </div>
        <div
          class="label"
        >
          <a @click="goToNumberSection">{{ $t(texts.changeNumber) }}</a>
        </div>
        <div class="btn-wrapper mt-0">
          <button-component
            :text="texts.codeSendBtn"
            :classes="btnClasses"
            :requires="['code']"
            :action="{
              fn: 'verifySms',
              params: ''
            }"
          />
        </div>
      </div>
    </ValidationObserver>
    <div v-if="step2Status" class="sms-step sms-step-2">
      <img :src="require('@/assets/images/png/sms-step.png')" class="sms-step-image"/>
    </div>
  </div>
</template>

<script>
import textInput from "@/components/renderers/text-input.vue";
import CountryFlag from 'vue-country-flag'
import { mapMutations } from 'vuex';
import VOtpInput from "@bachdgvn/vue-otp-input";

export default {
  name: "SmsVerification",
  components: {
    textInput,
    // CoolSelect,
    VOtpInput,
    CountryFlag
  },
  props: {
    fieldId: {
      type: String
    },
    successAction: {
      type: Object,
      default: () => {}
    },
    endpoint: {
      type: String,
      default: '/mobileAuth'
    },
    endpoint2: {
      type: String,
      default: '/mobileVerification'
    },
    onSuccess: {
      type: Object,
      default: () => {}
    },
    inputStyles:{
      type:Object,
      required:false,
      default:()=>{}
    },
    options: {
      type: Array,
      default: () => []
    },
    colorSchema: {
      type: String,
      default: "#5D5FEF"
    },
    btnClasses: {
      type: String,
      default: "btn-primary"
    },
    texts: {
      type: Object,
      default: () => ({
        phoneLabel: "Phone number",
        sendBtn: "Get SMS Code",
        codeLabel: "Verification code",
        codeSentLabel: "We sent you a verification code via sms",
        notReceived: "Didnt receive it?",
        clickHere: "Click here",
        changeNumber: "Change sms number",
        codeSendBtn: "Send",
      })
    },
    focusOnMount: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      selectedCountry: "",
      tel: "",
      code: "",
      state: "number",
      step1Status: false,
      step2Status: false
    };
  },
  watch: {
    selectedCountry (val) {
      this.countryCode = val.phoneCode;
    }
  },
  methods: {
    ...mapMutations(['setCountryCode', 'setTel']),
    async sendSMS () {
      const isValid = await this.$refs.numberForm.validate();
      if(!isValid) {
        this.scrollTo(`[id=numberForm`);
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
        return
      }
      const formData = {
        mobile: `${this.countryCode}${this.tel}`
      };
      this.$store.dispatch("generateCode", { formData, endpoint: this.endpoint })
        .then((res) => {
          this.setCountryCode(this.countryCode);
          this.setTel(this.tel);
          this.state = "code";
          this.code = ''
          document.querySelector('.otp-input').focus()
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: false
          });
          if(res.response){
            this.step1Status = true
          }
        })
        .catch ((e) => {
          if (this.isTester && process.env.NODE_ENV == 'development') {
            return this.state = "code"
          }
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: false
          });
        })
    },
    async verify () {
      const isValid = await this.$refs.codeForm.validate();
      if (!isValid) {
        this.scrollTo(`[id=codeForm`);
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
        return
      }

      const formData = {
        mobile: `${this.countryCode}${this.tel}`,
        code: this.code
      };
      this.$store.dispatch("verifyCode", { formData, endpoint: this.endpoint2 })
        .then(async r => {
          if (this.keepInState) {
            this.keepInState.forEach(element => {
              this.$store.commit("setField", { field: element, value: r[element] })
            });
          }
          this.state = "done";
          this.$emit("component-updated")
          if (this.fieldId == "lastStepIframe") {
            const resp2 = await this.$store.dispatch("lastStepIframe", "/joltxIframeLastStep")
            if (resp2.response) {
              const fn = this.successAction.fn;
              const params = this.successAction.params;
              this[fn](params, this);
              this.$eventHub.$emit("done-event-trigger", {
                parent: this,
                status: false
              });
              this.$store.dispatch("setComponentStatus", {
                component: this.fieldId,
                status: true
              });
              if(r.response){
                this.step2Status = true
              }
            } else {
              this.$eventHub.$emit("done-event-trigger", {
                parent: this,
                status: false
              });
            }
          } else {
            const fn = this.successAction.fn;
            const params = this.successAction.params;
            this[fn](params, this);
            this.$eventHub.$emit("done-event-trigger", {
              parent: this,
              status: false
            });
            this.$store.dispatch("setComponentStatus", {
              component: this.fieldId,
              status: true
            });
            if(r.response){
              this.step2Status = true
            }
          }
        })
        .catch(err => {
          if (this.isTester && process.env.NODE_ENV == 'development') {
            this.$emit("component-updated")
            this.$store.dispatch("setComponentStatus", {
              component: this.fieldId,
              status: true
            });
            const fn = this.successAction.fn;
            const params = this.successAction.params;
            return this[fn](params, this);
          }
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: false
          });
        });
    },
    handleOnChange(value) {
      this.code = value;
      this.$store.dispatch("setComponentStatus", {
        component: 'code',
        status: value && value.length == 4
      });
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    goToNumberSection () {
      this.state = "number";
      this.scrollTo(`[id=numberForm`);
    },
  },
  mounted () {
    this.$eventHub.$on('sendSms-event', this.sendSMS)
    this.$eventHub.$on('verifySms-event', this.verify)
    this.selectedCountry = this.options[0]
    if (this.focusOnMount) {
      document.getElementById('phoneNumber').focus()
    }

    this.scrollTo('[id=app');
  },
  computed: {
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  },
  beforeDestroy () {
    this.$eventHub.$off('sendSms-event')
    this.$eventHub.$off('verifySms-event')
  }
};
</script>
<style lang="scss" scoped>

.sms-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  > span {
    border-top: 46px solid var(--color);
    opacity: .5;
    display: flex;
    justify-content: center;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: all;
    }

    .inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 50px 0 20px 0;
      max-width: 80%;

      @media(max-width: 767px) {
        max-width: 100%;
      }

      .label {
        margin: 10px;
      }
      
      .btn-wrapper {
        margin-top: 40px;
        width: 100%;
        text-align: center;
        padding: 10px;
      }
    }
  }
}

.inputWrapper {
  display: flex;
  justify-content: center;
  div {
    margin: 0;
    padding: 0;
  }
}

.country-item {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
:deep(.vs__search) {
  display: none;
}

:deep(.otp-input) {
  width: 40px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid var(--color);
  border-radius: 4px;
  text-align: center;
  margin: 5px;
}

a {
  color: var(--color) !important;
  text-decoration: underline !important;
}
.sms-step{
  &-image{
    max-width: 100%;
    margin-bottom: 30px;
  }
  @media(min-width: 450px){
    max-width: 50% !important;
    &-1{
      position: absolute;
      left: 0%;
      bottom: -20%;
      padding-right: 0% !important;
    }
    &-2{
      position: absolute;
      right: 0%;
      bottom: -20%; 
      padding-left: 0% !important;
    }
    &-image{
      width: 100%;
    }
  }
}
</style>
