export default {
  // 'Log in to your account': '',
  // 'Email': '',
  // 'Password': '',
  // 'Forgot your password?': '',
  // 'Verification code': '',
  'Welcome back': 'Bine ai revenit!',
  'Log in to continue to GHH GRC': 'Conectați-vă pentru a continua la GHH GRC',
  'Confirm your identity and claim your refund': 'Confirmă identitatea și solicită rambursul',
  'Verify your identity by entering your phone number here. It is only used once for verification and not for advertising purposes. After the action, your phone number will be deleted by us.': 'Verificăm identitatea ta prin introducerea numărului de telefon aici. Acesta este folosit o singură dată pentru verificare și nu  în scopuri promoționale. După finalizarea verificării, vom șterge numărul tău de telefon',
  'Phone number': 'Număr telefon mobil',
  'Get SMS Code': 'Introdu codul de verificare primit',
  'We sent you a verification code via sms': 'Un cod ți-a fost trimis prin SMS',
  'Didnt receive it?': 'Nu l-ai primit?',
  'Click here': 'Click aici',
  'Submit': 'TRIMITE',
  'Change sms number': 'Schimbă numărul de telefon',
  'Provide bank details': 'Specifică detaliile bancare',
  'Please let us know to which account the refund amount should be transferred.': 'Te rog să ne informezi în ce cont dorești să primesti banii inapoi',
  'Account holder first name': 'Prenume titular',
  'Account holder last name': 'Nume titular',
  'Enter the frist name of the account holder': 'Introdu prenumele titularului contului bancar',
  'Enter the last name of the account holder': 'Introdu numele titularului contului bancar',
  'Enter your IBAN number': 'Introdu IBAN-ul',
  'Send': 'TRIMITE',
  'Login': 'AUTENTIFICA-TE',
  'Back': 'ÎNAPOI',
  'We got your bank information, and we’ll inform you via email when we’ll send the money to your bank account': 'Am primit datele tale bancare și te vom informa pe e-mail când a fost efectuat transferul în contul tău.',
  'Your entry has been updated with the new image. We will get back to you with its status in max. 24h.': 'Participarea ta a fost actualizata cu noua imagine. Vom reveni catre tine cu statusul acesteia in max. 24h.',
  'Back to campaign page': 'ÎNAPOI LA CAMPANIE // PRIMA PAGINĂ',
  'receipt-image': 'assets/images/png/receipt-youtil.png',
  'Upload a receipt': 'Incarca bonul tau/factura ta',
  'In this step, please upload the proof of purchase of your products.': 'La acest pas avem nevoie sa verificam daca pe bon/factura se regasesc produsele participante P&G',
  'Drop your photo here or select file.': 'Incarca aici imaginea produsului',
  'Only JPG, PNG and PDF files are supported Maximum file size: 10MB': 'Doar fisierele de tip JPG si sau PNG sunt acceptate. Limita maxima pentru un fisier este de 10 MB.',
  'Only JPG and PNG files are supported Maximum file size: 10MB': 'Doar fisierele de tip JPG si sau PNG sunt acceptate. Limita maxima pentru un fisier este de 10 MB.',
  'UPLOAD': 'Incarca',
  'The following information must be visible:': 'Urmatoarele informatii trebuie sa fie vizibile',
  'Folded or scanned receipts or invoices will not be accepted': 'Bonurile/facturile indoite sau scanate nu sunt acceptate',
  'Retailer / Shop': 'Magazin',
  'Campaign product and its price': 'Lista produselor inclusiv discount-uri',
  'Total sum of the receipt / invoice and applied VAT': 'Total bon inclusiv TVA',
  'Date and time of purchase': 'Data si ora achizitiei',
  'Receipt / Invoice ID (not the bank transaction ID)': 'Numarul bonului',
  'Valid': 'Valid',
  'Correct': 'Corect',
  'Hard to read': 'Ilizibil',
  'Not readable': 'Neclar',
  'Folded': 'Impaturit eronat',
  'Cut off': 'Incomplet',
  'Cropped': 'Incomplet',
  'Your receipt is now being loaded and processed.': 'Bonul tau se incarca acum si va fi procesat.',
  'The receipt will be processed automatically. This may take a minute. Please be patient.': 'Bonul va fi procesat automat. Acest lucru poate dura cateva minute. Te rugam sa ai rabdare.',
  'If you get an error message, please get a better version of the receipt and upload it again.': 'In cazul in care primesti un mesaj de eroare, te rugam sa incarci din nou o versiune mai buna a bonului tau.',
  'Please drag your receipt inside the gray rectangle.': 'Te rugam sa incadrezi bonul / factura in chenarul gri.',
  'You can erase private information with the Eraser tool.': 'Poti folosi instrumentul de stergere daca iti doresti sa acoperi informatii personale ce se regasesc pe bon.',
  'Crop': 'Taie',
  'Erase': 'Sterge',
  'Drag': 'Incadreaza',
  'Zoom in': 'Mareste',
  'Zoom out': 'Micsoreaza',
  'Please upload your product photo here': 'Incarca o folografie cu produsul tau',
  'Please upload a photo of the back of your product.': 'Te rugam sa incarci o fotografie cu partea din spate a produsului tau.',
  // 'Thank you': '',
  // 'Thank you for resubmitting your bank details. So there should now be a successful transfer.': '',
  'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.': 'Formatul fisierului nu este acceptat. Doar fisiere  .jpeg,  .png  si .pdf sunt acceptate.',
  'This file format is not supported. Only .jpeg and .png files are accepted.': 'Formatul fisierului nu este acceptat. Doar fisiere  .jpeg si .png sunt acceptate.',
  'This file is too big. Only files up to 10MB can be uploaded.': 'Fisierul incarcat este prea mare. Doar fisierele cu o dimensiune maxima de 10 MB pot fi incarcate.',
  'Copied to clipboard': 'Copiat',
  'Oops!!!': 'Ups!!!',
  'Oops, something went wrong. Please try again.': 'Ups, ceva nu a mers. Te rugam sa incerci din nou.',
  'The link you are trying to access has expired. Please try again.': 'Link-ul pe care incerci sa il accesezi a expirat. Te rugam sa incerci din nou.',
  'Please make sure that the image shows the back of the purchased product(s). It is important that the barcode is visible when you take the photo.': 'Te rugam sa te asiguri ca in imagine se regaseste partea din spate a produsului / produselor achizitionat/e. Este important sa fie vizibil codul de bare atunci cand faci fotografia.',
  'Request your payout': 'Incarca datele tale bancare',
  'Please enter the information of your account to which the refund amount will be transferred.': 'Te rugam sa ne transmiti in ce cont doresti sa facem plata sumei acumulate',
  'Beneficiary first name': 'Prenume beneficiar',
  'Beneficiary last name': 'Nume beneficiar',

  errors: {
    default: "Eroare!!! Ceva nu a mers!",
    customerToken: "Eroare!!! Ceva nu a mers, te rugam sa incerci din nou!",
    invalidCode: "Conexiunea a expirat. Te rugam sa soliciti un nou link pentru resetarea parolei.",
    manyTries: " A aparut o eroare la incarcarea paginii, deoarece a fost incarcata de prea multe ori.",
    generateCode: "Codul de verificare nu a putut fi trimis care numarul de telefon indicat, deoarece nu este permis. Te rugam sa verifici numarul de telefon introdus si sa incerci din nou.",
    wrongCode: "Codul din SMS nu este corect.",
    network: "Eroare. A aparut o eroare de server. Te rugam sa incerci din nou mai tarziu.",
    invalidReceipt: "Imaginea nu reprezinta un bon/ o factura. Te rugam sa incarci fotografia unui bon.",
    'Campaign not found!': "Eroare. Te rugam sa incerci din nou mai tarziu.",
    'User not found': "Adresa de email sau parola introdusa este gresita. Trebuie sa te loghezi utilizand datele contului folosit pentru a participa la campanie.",
    'Login Failed': "Email-ul sau parola introdus/a este eronata. Este necesar sa te loghezi utilizand datele contului pe care le-ai introdus in momentul pentru participare.",
    'Login failed': "Email-ul sau parola introdus/a este eronata. Este necesar sa te loghezi utilizand datele contului pe care le-ai introdus in momentul pentru participare.",
    'Code is incorrect': "Codul primit prin SMS a fost introdus eronat. Te rugam sa incerci din nou."
  },
  dictionary: {
    ro: {
      messages: {
        "ibanName": "{_field_} poate conține numai litere, cratime și spații",
        "iban": "IBAN-ul este invalid",
        "phoneNumber": "Numărul de telefon este invalid",
      },
      names: {
        iban: 'IBAN',
        firstname: 'Nume',
        firstName: 'Nume',
        lastname: 'Prenume',
        lastName: 'Prenume',
        phoneNumber: 'Număr telefon mobil',
        code: 'Cod de verificare',
      }
    }
  },

}
