export const AUTH_CONFIG = {
  gr: {
    "domain": "account.epithimies.gr",
    "clientId": "MO8YHvEC66ju2JzDCTemomE3Uh9s07c8"
  },
  ro: {
    "domain": "cont.youtil.ro",
    "clientId": "GcRZtU68sYT1AUX0ucqpUNP8qGZgOomP",
    "audience": "CGW",
  },
}
